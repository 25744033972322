import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import walletReducer from './walletSlice';
import userReducer from './userSlice';
import transactionReducer from './transactionSlice';
import cryptoReducer from './cryptoSlice';
import walletMiddleware from './walletMiddleware';

const store = configureStore({
    reducer: {
        app: appReducer,
        wallet: walletReducer,
        transaction: transactionReducer,
        user: userReducer,
        crypto: cryptoReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(walletMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;