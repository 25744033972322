
import { createSlice, PayloadAction, PayloadActionCreator } from "@reduxjs/toolkit";
import { sendStatusCode } from "next/dist/server/api-utils";

type AppState = {
    isActive: boolean;
    isLoading: boolean;
    isMenuOpen: boolean;
    isModalOpen: boolean;
    isDialogOpen: boolean;
    isDrawerOpen: boolean;
    isSettingsOpen: boolean;
    isPopupOpen: boolean;
    isSheetOpen: boolean;
    isLoadingFees: boolean;
    isValidating: boolean;
    mainButtonClick: boolean,
    isCryptoFeesError: boolean,
    isCryptoNetworksError: boolean,
    isLoadingCryptoNetworks: boolean,
};

const initialState: AppState = {
    isActive: true,
    isLoading: false,
    isMenuOpen: false,
    isModalOpen: false,
    isDialogOpen: false,
    isDrawerOpen: false,
    isSettingsOpen: false,
    isPopupOpen: false,
    isSheetOpen: false,
    isLoadingFees: false,
    isValidating: false,
    mainButtonClick: false,
    isCryptoFeesError: false,
    isCryptoNetworksError: false,
    isLoadingCryptoNetworks: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setActive: (state: AppState, action: PayloadAction<boolean>) => {
            state.isActive = action.payload;
        },
        setIsLoading: (state: AppState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setMenuOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isMenuOpen = action.payload;
        },
        setIsModalOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isModalOpen = action.payload;
        },
        setDialogOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isDialogOpen = action.payload;
        },
        setSettingsOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isSettingsOpen = action.payload;
        },
        setDrawerOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isDrawerOpen = action.payload;
        },
        setSheetOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isSheetOpen = action.payload;
        },
        setIsLoadingFees: (state: AppState, action: PayloadAction<boolean>) => {
            state.isLoadingFees = action.payload;
        },
        setIsValidating: (state: AppState, action: PayloadAction<boolean>) => {
            state.isValidating = action.payload;
        },
        touchMainButton: (state: AppState) => {
            state.mainButtonClick = !state.mainButtonClick;
        },
        setIsCryptoNetworksError: (state: AppState, action: PayloadAction<boolean>) => {
            state.isCryptoNetworksError = action.payload;
        },
        setIsCryptoFeesError: (state: AppState, action: PayloadAction<boolean>) => {
            state.isCryptoFeesError = action.payload;
        },
        setIsLoadingCryptoNetworks: (state: AppState, action: PayloadAction<boolean>) => {
            state.isLoadingCryptoNetworks = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setMenuOpen,
    setIsModalOpen,
    setDialogOpen,
    setSettingsOpen,
    setDrawerOpen,
    setSheetOpen,
    setIsLoadingFees,
    setIsValidating,
    touchMainButton,
    setIsCryptoNetworksError,
    setIsCryptoFeesError,
    setIsLoadingCryptoNetworks,
} = appSlice.actions;

export default appSlice.reducer;
