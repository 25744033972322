export const AppConfig = {
    baseApiUrl: 'https://tgcache.link/api/web-app',
    telegram: {
        minRequiredVersionFor: {
            ACCESS: '6.0',
            BUTTOM_BUTTONS: '6.2',
            CLOUD_STORAGE: '6.9',
            SET_HEADER_COLOR: '6.1',
            SET_BACKGROUND_COLOR: '6.1',
            SET_FOOTER_COLOR: '7.1',
            CLOSING_CONFIRMATION: '6.2',
            VERTICAL_SWIPES: '7.7',
            FULLSCREEN: '8.0',
            ORIENTATION: '8.0',
            ADDING_TO_HOME_SCREEN: '8.0',
            SWITCHING_INLINE_QUERY: '6.8',
            SHOWING_MODALS: '6.2',
            QR: '6.4',
            TELEGRAM_WEB_BROWSER: '7.0',
            OPENING_LINKS: '6.4',
            INVOICES: '6.1',
            STORIES: '7.8',
            SHARING_MESSAGES: '8.1',
            EMOJI_STATUS: '8.0',
            DOWNLOAD_FILE: '8.0',
        },
    },
    params: {
        CRYPTO_CURRENCIES_UPDATE_INTERVAL: 120000,
        TOTAL_BALANCE_UPDATE_INTERVAL: 120000,
        USER_AUTH_TIMEOUT: 6000,
    },
    defaultThemes: {
        light: {
            subtitle_text_color: "#8e8e93",
            link_color: "#007aff",
            bottom_bar_bg_color: "#f2f2f2",
            secondary_bg_color: "#efeff4",
            section_bg_color: "#ffffff",
            section_header_text_color: "#6d6d72",
            bg_color: "#ffffff",
            button_color: "#007aff",
            button_text_color: "#ffffff",
            header_bg_color: "#f8f8f8",
            section_separator_color: "#c8c7cc",
            hint_color: "#8e8e93",
            accent_text_color: "#007aff",
            destructive_text_color: "#ff3b30",
            text_color: "#000000",
        },
        dark: {
            button_color: "#3e88f7",
            hint_color: "#98989e",
            bg_color: "#000000",
            secondary_bg_color: "#1c1c1d",
            section_header_text_color: "#8d8e93",
            subtitle_text_color: "#98989e",
            destructive_text_color: "#eb5545",
            section_separator_color: "#545458",
            button_text_color: "#ffffff",
            link_color: "#3e88f7",
            bottom_bar_bg_color: "#1d1d1d",
            accent_text_color: "#3e88f7",
            section_bg_color: "#2c2c2e",
            text_color: "#ffffff",
            header_bg_color: "#1a1a1a",
        },
    },
};

export const ApiEndpoints = {
    auth: {
        AUTH_USER: 'auth/authorize-user',
    },
    userProfile: {
        GET_TIME_OFFSET: 'user/profile/get-time-offset',
        SWITCH_LANGUAGE: 'user/profile/switch-language',
        GET_SEED_PHRASE: 'user/profile/get-seed-phrase',
        GET_REFERRAL_URL: 'user/profile/get-referral-url',
    },
    wallet: {
        GET_CRYPTO_CURRENCY: 'user/wallet/get-crypto-currency',
        GET_CRYPTO_CURRENCIES: 'user/wallet/get-crypto-currencies',
        GET_TOTAL_BALANCE: 'user/wallet/get-total-balance',
        GET_TOTAL_PROFIT: 'user/wallet/get-total-profit',
        GET_WALLET_STATS: 'user/wallet/get-wallet-stats',
        GET_TRANSACTIONS: 'user/wallet/get-transactions',
        GET_TOTAL_TRANSACTIONS: 'user/wallet/get-total-transactions',
        GET_TRANSACTION_DETAILS: 'user/wallet/get-transaction-details',
        GET_BOT_USER_TOKEN_STATS: 'user/wallet/get-bot-user-token-portfolio-stats',
        GET_TOKEN_STAKINGS: 'user/wallet/get-token-stakings',
        GET_TOKEN_STAKING_STATS: 'user/wallet/get-token-staking-stats',
        GET_TOKEN_STAKING_DETAILS: 'user/wallet/get-token-staking-details',
        GET_NEXT_PENDING_BOT_USER_TOKEN_STAKE: 'user/wallet/get-next-pending-bot-user-token-stake',
        GET_LINKED_TOP_UP_ADDRESS: 'user/wallet/get-linked-top-up-address',
        GET_WITHDRAWAL_ADDRESSES: 'user/wallet/get-withdrawal-addresses',
        GET_TRANSFER_RECIPIENTS: 'user/wallet/get-transfer-recipients',
        GET_REFERRAL_STATS: 'user/wallet/get-referral-stats',
        GET_REFERRAL_URL: 'user/wallet/get-referral-url',
        COMMIT_TRANSACTION: 'user/wallet/commit-transaction',
    },
    crypto: {
        GET_CRYPTO_CURRENCY_PRICE: 'crypto/crypto-currency/get-price',
        GET_CRYPTO_CURRENCY_CHART_DATA: 'crypto/crypto-currency/get-chart-data',
        GET_CRYPTO_CURRENCY_MARKET_DATA: 'crypto/crypto-currency/get-market-data',
        GET_CRYPTO_CURRENCY_MARKET_STATS: 'crypto/crypto-currency/get-market-stats',
        GET_CRYPTO_CURRENCY_INFO: 'crypto/crypto-currency/get-info',
        GET_TRENDING_CRYPTO_CURRENCIES: 'crypto/get-trending-crypto-currencies',
        GET_TOP_MCAP_CRYPTO_CURRENCIES: 'crypto/get-top-mcap-crypto-currencies',
        GET_CRYPTO_NETWORKS: 'crypto/networks/get',
        GET_CRYPTO_FEES: 'crypto/get-fees',
        GET_TOP_UP_ADDRESS: 'crypto/get-top-up-address',
        GET_TOKEN_STAKING_TERMS: 'crypto/token/get-token-staking-terms',
        GET_TOKEN_STAKING_INFO: 'crypto/token/get-token-staking-info',
        VALIDATE_ADDRESS: 'crypto/validate-address',

        GET_TOKEN_BASE_INFO: 'crypto/token/get-token-base-info',
        GET_TOKEN_WIDGET_DATA: 'crypto/token/get-widget-data',
    },
    service: {
        LOGGER: 'service/logger',
        GET_TRANSFER_RECIPIENT: 'service/get-transfer-recipient',
        VALIDATE_USER_ID: 'service/validate-user-id',
        GET_STATIC_PAGE_DATA: 'service/get-static-page-data',
    },
}