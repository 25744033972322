'use client';

import './globals.css';
import localFont from "next/font/local";
import Script from "next/script";
import React, { useCallback, useState } from "react";
import { Provider } from "react-redux";
import store from '@/store/store';
import { logError, logInfo } from "@/lib/actions";
import { IWebApp } from "@/types/telegram";
import { AppProvider } from "@/providers/app-provider";
import { TelegramProvider } from "@/providers/telegram-provider";
import { UiProvider } from "@/providers/ui-provider";
import { Nunito_Sans } from 'next/font/google';
import { StyleProvider } from "@/providers/style-provider";
import Footer from "@/components/Footer";
import Head from "next/head";
import { Toaster } from "sonner";
import { LocaleProvider } from "@/providers/locale-provider";
import Header from "@/components/Header";

const nunito = Nunito_Sans({
    subsets: ['latin', 'cyrillic', 'latin-ext', 'cyrillic-ext'],
    weight: ['400', '500', '600', '700', '800'],
    display: 'swap',
    variable: "--font-sans",
});

const geistMono = localFont({
    src: "./fonts/GeistMonoVF.woff",
    variable: "--font-geist-mono",
    weight: "100 900",
});

export default function RootLayout(props: { children: React.ReactNode }) {
    const [webApp, initWebApp] = useState<IWebApp | undefined>(undefined);

    const initialize = useCallback(() => {
        if (typeof window !== 'undefined') {
            const timeoutId = setTimeout(() => {
                if (!webApp) {
                    logError('TelegramProvider: WebApp initialization timeout after 4 seconds.');
                    console.error('TelegramProvider: WebApp initialization timeout after 4 seconds.');
                }
            }, 4000);

            try {
                const webAppInstance = (window as any).Telegram?.WebApp;
                if (webAppInstance) {
                    initWebApp(webAppInstance as IWebApp);
                    clearTimeout(timeoutId);
                } else {
                    throw new Error('TelegramProvider: WebApp cannot be initialized. Data not found.');
                }
            } catch (error: any) {
                logError('TelegramProvider: failed on initializing WebApp.', { ...error });
                if (webApp) webApp.close();
            }
        }
    }, [webApp]);

    return (
        <html lang="en">
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <meta name="robots" content="noindex, nofollow" />
                <meta http-equiv="Cache-Control" content="no-store" must-revalidate />
                <meta http-equiv="Pragma" content="no-cache" />
                <meta http-equiv="Expires" content="0" />
            </Head>
            <body className={`${nunito.className} ${geistMono.variable} antialiased`}>
                <Script
                    src="https://telegram.org/js/telegram-web-app.js"
                    strategy="lazyOnload"
                    onLoad={initialize}
                    onError={(error: unknown) => {
                        logError(`TelegramProvider: failed to load Telegram WebApp script.`, error as any);
                        console.error(`TelegramProvider: failed to load Telegram WebApp script.`, error);
                    }}
                />
                {webApp && (
                    <Provider store={store}>
                        <TelegramProvider webApp={webApp}>
                            <LocaleProvider languageCode={webApp.initDataUnsafe.user?.language_code}>
                                <StyleProvider>
                                    <Toaster richColors theme={webApp.colorScheme} position="top-right" visibleToasts={2} />
                                    <UiProvider>
                                        <AppProvider>
                                            <Header />
                                            <main className="max-w-[600px] w-full mx-auto overflow-hidden">
                                                {props.children}
                                            </main>
                                            <Footer />
                                        </AppProvider>
                                    </UiProvider>
                                </StyleProvider>
                            </LocaleProvider>
                        </TelegramProvider>
                    </Provider>
                )}
            </body>
        </html>
    );
}
