'use client';

import { Button } from "./ui/button";
import { useUi } from "@/contexts/ui-context";
import { AppDispatch } from "@/store/store";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useApp } from "@/contexts/app-context";
import { setIsProceed } from "@/store/transactionSlice";
import { useTelegram } from "@/contexts/telegram-context";
import { AppConfig } from "@/lib/appConfig";

export default function Footer() {
    const { t } = useTranslation();
    const { webApp } = useTelegram();
    const { userId } = useApp();
    const dispatch: AppDispatch = useDispatch();
    const { isMainMenuOpen, closeMainMenu, openMainMenu } = useUi();

    if (webApp.isVersionAtLeast(AppConfig.telegram.minRequiredVersionFor.BUTTOM_BUTTONS)) {
        return null;
    } else {
        return (
            <footer className="p-4 space-x-2 flex items-center justify-center">
                {isMainMenuOpen ? (
                    <Button onClick={() => closeMainMenu()}>{t('close')}</Button>
                ) : (
                    <Button onClick={() => openMainMenu()}>{t('menu')}</Button>
                )}
                <Button onClick={() => dispatch(setIsProceed(true))}>Proceed</Button>
                <div className="text-xs text-muted-foreground"><code>{userId}</code></div>
            </footer>
        )
    }
};