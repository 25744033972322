'use client';

import { HapticFeedbackImpactLevel, HapticNotificationType, IHapticFeedback } from '@/types/telegram';
import { useCallback, useMemo } from 'react';

export type ImpactOccurredFunction = (style: HapticFeedbackImpactLevel) => void;
export type NotificationOccurredFunction = (type: HapticNotificationType) => void;
export type SelectionChangedFunction = () => void;

export const useHapticFeedback = (hapticFeedback: IHapticFeedback): {
    impact: ImpactOccurredFunction;
    notification: NotificationOccurredFunction;
    selectionChanged: SelectionChangedFunction;
} => {
    const impact = useCallback((level: HapticFeedbackImpactLevel) => {
        hapticFeedback.impactOccurred(level);
    }, [hapticFeedback]);

    const notification = useCallback((type: HapticNotificationType) => {
        hapticFeedback.notificationOccurred(type);
    }, [hapticFeedback]);

    const selectionChanged = useCallback(() => {
        hapticFeedback.selectionChanged();
    }, [hapticFeedback]);

    return useMemo(() => ({
        notification,
        impact,
        selectionChanged,
    }), [hapticFeedback]);
};

export default useHapticFeedback;
