/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';

import * as React from "react";
import { ThemeProvider as NextThemesProvider, ThemeProvider } from "next-themes";
import { useTelegram } from "../contexts/telegram-context";
import { useMemo, useState } from "react";
import { addColorTransparency, percentageToFloat } from "../lib/utils";
import { IThemeParams } from "../types/telegram";
import { ColorScheme, Level } from "../types/enums";
import { StyleContext } from "@/contexts/style-context";
import { AppConfig } from "@/lib/appConfig";
import { useTranslation } from "react-i18next";
import { logWarning } from "@/lib/actions";

export enum Color {
    Success = '#10b981',
    Danger = '#ef4343',
    Info = '#2570a9',
    Warning = '#D65513',
    Light = '#fafafa',
    Dark = '#18181b',
    Black = '#00000',
    White = '#fffff',
    Purple = '#b392f0',
    Gold = "#ffe08b",
};

export function StyleProvider({ children, ...props }: React.ComponentProps<typeof NextThemesProvider>) {
    const { webApp } = useTelegram();
    const { t } = useTranslation();
    const [themeParams, setThemeParams] = useState<Partial<IThemeParams>>(webApp.themeParams);
    const [colorScheme, setColorScheme] = useState<ColorScheme>(webApp.colorScheme);
    const defaultThemes = AppConfig.defaultThemes;

    const colors = {
        danger: "#ef4343",
        warning: "#D65513",
        info: "#2570a9",
        success: "#10b981",
        light: "#fafafa",
        dark: "#18181b",
        sectionBg: {
            light: "#e4e4e7",
            dark: "#3f3f46",
        },
        sectionSeparator: {
            light: "#d4d4d8",
            dark: "#52525b",
        },
        secondaryBgColor: {
            light: "#f1f1f1",
            dark: "#fffff",
        }
    };

    const styles = useMemo(() => ({
        accentTextColor: themeParams.accent_text_color ?? defaultThemes[colorScheme].accent_text_color ?? "#007aff",
        bgColor: themeParams.bg_color ?? defaultThemes[colorScheme].bg_color ?? "#fffff",
        bottomBarBgColor: themeParams.bottom_bar_bg_color ?? (colorScheme === 'light' ? defaultThemes.light.bottom_bar_bg_color : defaultThemes.dark.bottom_bar_bg_color),
        buttonColor: themeParams.button_color ?? (colorScheme === 'light' ? defaultThemes.light.button_color : defaultThemes.dark.button_color),
        buttonTextColor: themeParams.button_text_color ?? (colorScheme === 'light' ? defaultThemes.light.button_text_color : defaultThemes.light.button_text_color),
        destructiveTextColor: themeParams.destructive_text_color ?? (colorScheme === 'light' ? defaultThemes.light.destructive_text_color : defaultThemes.dark.destructive_text_color),
        headerBgColor: themeParams.header_bg_color ?? (colorScheme === 'light' ? defaultThemes.light.header_bg_color : defaultThemes.dark.header_bg_color),
        hintColor: themeParams.hint_color ?? (colorScheme === 'light' ? defaultThemes.light.hint_color : defaultThemes.dark.hint_color),
        linkColor: themeParams.link_color ?? (colorScheme === 'light' ? defaultThemes.light.link_color : defaultThemes.dark.link_color),
        secondaryBgColor: themeParams.secondary_bg_color ?? (colorScheme === 'light' ? defaultThemes.light.secondary_bg_color : defaultThemes.dark.secondary_bg_color),
        sectionBgColor: themeParams.section_bg_color ?? (colorScheme === 'light' ? defaultThemes.light.secondary_bg_color : defaultThemes.dark.section_bg_color),
        sectionHeaderTextColor: themeParams.section_header_text_color ?? (colorScheme === 'light' ? defaultThemes.light.section_header_text_color : defaultThemes.dark.section_header_text_color),
        sectionSeparatorBgColor: themeParams.section_separator_color ?? (colorScheme === 'light' ? defaultThemes.light.section_separator_color : defaultThemes.dark.section_separator_color),
        subtitleTextColor: themeParams.subtitle_text_color ?? (colorScheme === 'light' ? defaultThemes.light.subtitle_text_color : defaultThemes.dark.subtitle_text_color),
        textColor: themeParams.text_color ?? (colorScheme === 'light' ? defaultThemes.light.text_color : defaultThemes.dark.text_color),
        successColor: Color.Success,
        dangerColor: Color.Danger,
        warningColor: Color.Warning,
        infoColor: Color.Info,
        blackColor: Color.Black,
        whiteColor: Color.White,
        goldColor: Color.Gold,
    }), [themeParams, colorScheme]);

    const toastThemes = (toastId: string) => {
        const loading = {
            id: toastId,
            title: t('loading'),
            description: t('please_wait'),
            type: 'loading',
        }
    }

    const getTrendColor = (trendFactor: number | string): string => {
        const trend = percentageToFloat(trendFactor);

        if (trend > 0) {
            return styles.successColor;
        } else if (trend === 0) {
            return styles.buttonColor;
        } else {
            return styles.destructiveTextColor;
        }
    };

    function getErrorToast(toastId: string) {
        return {
            toastId,
            title: t('bot_error.title'),
            description: t('bot_error.desc'),
            type: Level.Error,
            duration: 2400,
            dismissible: true,
            closeButton: false,
        };
    }

    function getLoadingToast(toastId: string) {
        return {
            toastId,
            title: t('loading'),
            description: t('please_wait'),
            type: Level.Info,
        }
    }

    function getLevelColor(level?: Level): string {
        switch (level) {
            case Level.Error:
                return colors.danger;
            case Level.Warning:
                return colors.warning;
            default:
                return colors.info;
        }
    }

    function getBalanceColor(balance: number): string {
        if (balance > 0) {
            return styles.textColor;
        } else {
            return addColorTransparency(styles.textColor, 0.5);
        }
    };

    const getStatusColor = (status: string): string => {
        switch (status) {
            case 'pending':
                return styles.warningColor;
            case 'failed':
                return styles.destructiveTextColor;
            case 'completed':
            case 'finished':
                return styles.successColor;
            default:
                logWarning(`useStyle: cannot get status color for ${status} condition.`);
                return styles.hintColor;
        }
    };

    return (
        <StyleContext.Provider value={{ 
            colorScheme,
            colors,
            styles,
            toastThemes,
            getTrendColor,
            getErrorToast,
            getLoadingToast,
            getLevelColor,
            getBalanceColor,
            getStatusColor,
        }}>
            <ThemeProvider
                defaultTheme={colorScheme as string}
                disableTransitionOnChange
                attribute="class"
                enableSystem={false}
            >
            {children}
            </ThemeProvider>
        </StyleContext.Provider>
    );
};