'use client';

import { ISettingsButton } from "@/types/telegram";
import { useCallback, useMemo } from "react";

export type HideSettingsButtonFunction = () => void;
export type ShowSettingsButtonFunction = () => void;

const useSettingsButton = (settingsButton: ISettingsButton): {
    hide: HideSettingsButtonFunction;
    show: ShowSettingsButtonFunction;
} => {
    const show: ShowSettingsButtonFunction = useCallback(() => {
        if (!settingsButton.isVisible) {
            settingsButton.show();
        }
    }, [settingsButton]);

    const hide: HideSettingsButtonFunction = useCallback(() => {
        if (settingsButton.isVisible) {
            settingsButton.hide();
        }
    }, [settingsButton]);

    return useMemo(() => ({ show, hide }), [settingsButton]);
};

export default useSettingsButton;