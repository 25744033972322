
import ApiException from "@/exceptions/ApiException";
import { sendGetRequest } from "@/lib/actions";
import { ApiEndpoints } from "@/lib/appConfig";
import { IApiException } from "@/types/api";
import { ICryptoFees, ICryptoNetwork } from "@/types/crypto";
import { Channel, Currency, Network } from "@/types/enums";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export const loadCryptoNetworks = createAsyncThunk<ICryptoNetwork[], void, { rejectValue: IApiException }>(
    "networks/loadCryptoNetworks",
    async (_, { rejectWithValue }) => {
        try {
            const apiResponse = await sendGetRequest<ICryptoNetwork[] | IApiException>(ApiEndpoints.crypto.GET_CRYPTO_NETWORKS);

            if (!apiResponse.success) {
                throw new ApiException(apiResponse.data as IApiException);
            }

            return apiResponse.data as ICryptoNetwork[];
        } catch (error: unknown) {
            if (error instanceof ApiException) {
                const apiError: IApiException = {
                    message: error.message,
                    action: error.apiAction,
                };
                return rejectWithValue(apiError);
            }

            const unexpectedError: IApiException = {
                message: (error as Error).message || "Fetching networks failed.",
                action: null,
            };
            return rejectWithValue(unexpectedError);
        }
    }
);

export const loadCryptoFees = createAsyncThunk<ICryptoFees, { channel: Channel; currency: Currency; network: Network | null }, { rejectValue: IApiException }>(
    "crypto/loadCryptoFees",
    async (payload, { rejectWithValue }) => {
        try {
            const apiResponse = await sendGetRequest<ICryptoFees | IApiException>(
                ApiEndpoints.crypto.GET_CRYPTO_FEES,
                payload
            );

            if (!apiResponse.success) {
                throw new ApiException(apiResponse.data as IApiException);
            }

            return apiResponse.data as ICryptoFees;
        } catch (error: unknown) {
            if (error instanceof ApiException) {
                const apiError: IApiException = {
                    message: error.message,
                    action: error.apiAction,
                };
                return rejectWithValue(apiError);
            }

            const unexpectedError: IApiException = {
                message: (error as Error).message || "Fetching crypto fees failed.",
                action: null,
            };
            return rejectWithValue(unexpectedError);
        }
    }
);

type CryptoState = {
    cryptoNetworks: ICryptoNetwork[] | null;
    cryptoFees: ICryptoFees | null;
    isLoading: boolean;
    isError: boolean;
    error: unknown;
};

const initialState: CryptoState = {
    cryptoNetworks: null,
    cryptoFees: null,
    isLoading: false,
    isError: false,
    error: null,
};

const cryptoSlice = createSlice({
    name: 'crypto',
    initialState,
    reducers: {
        setCryptoFees: (state: CryptoState, action: PayloadAction<ICryptoFees | null>) => {
            state.cryptoFees = action.payload;
        },
        setIsFeesError: (state: CryptoState, action: PayloadAction<boolean>) => {
            state.isError = action.payload;
        },
        setCryptoNetworks: (state: CryptoState, action: PayloadAction<ICryptoNetwork[] | null>) => {
            state.cryptoNetworks = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCryptoNetworks.pending, (state) => {
                state.cryptoNetworks = null;
                state.error = null;
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(loadCryptoNetworks.fulfilled, (state, action) => {
                state.cryptoNetworks = action.payload;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(loadCryptoNetworks.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload?.message || "Failed to load crypto networks.";
                state.isError = true;
            })

            .addCase(loadCryptoFees.pending, (state) => {
                state.cryptoFees = null
                state.error = null;
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(loadCryptoFees.fulfilled, (state, action) => {
                state.cryptoFees = action.payload;
                state.error = null;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(loadCryptoFees.rejected, (state, action) => {
                state.cryptoFees = null;
                state.isLoading = false;
                state.isError = true;
                state.error = action.payload?.message || "Failed to load crypto fees.";
            });
    },
});

export const {
    setCryptoFees,
    setCryptoNetworks,
    setIsFeesError,
} = cryptoSlice.actions;

export default cryptoSlice.reducer;
