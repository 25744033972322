/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import { ReactNode, useCallback, useEffect, useState } from "react";
import { AppContext } from "../contexts/app-context";
import { useUi } from "../contexts/ui-context";
import { usePathname, useRouter } from "next/navigation";
import { IApiAction, IApiException, IBotUser, IRedirectAction, IToastAction } from "@/types/api";
import { useTelegram } from "../contexts/telegram-context";
import ApiException from "../exceptions/ApiException";
import UnauthorizedException from "../exceptions/UnauthorizedException";
import AccessDeniedException from "../exceptions/AccessDeniedException";
import { createLogService } from "../services/LoggerService";
import { selectTransactionStatus } from "@/store/transactionSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppDispatch, RootState } from "@/store/store";
import { setIsConfirmed, setIsProceed } from "@/store/transactionSlice";
import { handleApiResponse } from "@/lib/utils";
import { HapticNotificationType } from "@/types/telegram";
import { Channel, Level } from "@/types/enums";
import { ApiEndpoints, AppConfig } from "@/lib/appConfig";
import { sendPostRequest } from "@/lib/actions";
import { useLocale } from "@/contexts/locale-context";
import { setCryptoCurrencies, setWalletStats } from "@/store/walletSlice";

export const AppProvider = ({ children }: { children: ReactNode }) => {
    const router = useRouter();
    const pathname = usePathname();
    const { t, i18n } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const { switchLanguage } = useLocale();
    const { webApp, cloudStorage, hapticFeedback, mainButton, secondaryButton, backButton, settingsButton } = useTelegram();
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
    const { setMainButtonParams, setSecondaryButtonParams, hideModal, openMainMenu, closeMainMenu, showDialog, hideDialog, showDrawer, hideDrawer, showSheet, openSettingsMenu, hideSheet, showToast, isToastVisible, hideToast } = useUi();
    const [user, setUser] = useState<IBotUser | null>(null);
    const [userId, setUserId] = useState<number>(webApp.initDataUnsafe.user?.id ?? 666);
    const logger = createLogService(userId, 'AppProvider');
    const [step, setStep] = useState<number | null>(null);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const channel = useSelector((state: RootState) => state.transaction.channel);
    const { isProceed, isConfirmed, isCommitted, isProcessing, isEnoughBalance } = useSelector(selectTransactionStatus);
    const homePath = `/${userId}`;
    const isHomePath = pathname === homePath || pathname === '/';
    const { selectedCurrency, selectedCurrencyToBuy, selectedRecipient, selectedNetwork, address } = useSelector((state: RootState) => state.transaction);

    const login = useCallback((user: IBotUser) => {
        setUserId(user.user_id);
        setUser(user);
        setIsAuthorized(true);
        setIsInitialized(true);
    }, []);

    const restoreCachedData = useCallback(async () => {
        try {
            const cachedLocale = await cloudStorage.getItem('preferred_language');
            if (cachedLocale) {
                void switchLanguage(cachedLocale);
                setIsInitialized(true);
            }

            const cachedCryptoCurrencies = await cloudStorage.getItem('crypto_currencies');
            if (cachedCryptoCurrencies) {
                const cryptoCurrencies = JSON.parse(cachedCryptoCurrencies);
                if (Array.isArray(cryptoCurrencies) && cryptoCurrencies.length > 10) {
                    dispatch(setCryptoCurrencies(cryptoCurrencies));
                }
            }

            const cachedWalletStats = await cloudStorage.getItem('wallet_stats');
            if (cachedWalletStats) {
                const walletStats = JSON.parse(cachedWalletStats);
                dispatch(setWalletStats(walletStats));
                logger.info(`cached wallet stats found and restored.`);
            }
        } catch (error: any) {
            logger.error('restoring cached data failed.', error);
        }
    }, [cloudStorage]);

    const handleAuthorize = useCallback(async () => {
        try {
            logger.info('Authorizing user...', { ...webApp });
            const apiResponse = await sendPostRequest<IBotUser | IApiException>(ApiEndpoints.auth.AUTH_USER, webApp, webApp.initDataUnsafe.user?.id ?? null);
            const user = handleApiResponse<IBotUser>(apiResponse);
            await switchLanguage(user.preferred_language);
            login(user);
            setIsInitialized(true);
        } catch (error: any) {
            if (error instanceof ApiException) {
                logger.error('API exception occurred.');
            } else {
                logger.error(`authorization failed.`, { error });
            }
        }
    }, [webApp]);

    const handleApiAction = useCallback(async (apiAction: IApiAction) => {
        switch (apiAction.type) {
            case 'close':
                break;
            case 'toast':
                showToast(apiAction.content as IToastAction);
                break;
            case 'modal':
                break;
            case 'popup':
                
                break;
            case 'redirect':
                const redirectAction = apiAction.content as IRedirectAction;
                router.push(redirectAction.url);
                break;
        }
    }, [showToast]);

    const handleApiException = useCallback((apiException: ApiException): void => {
        logger.warning(apiException.message);
        if (apiException.apiAction) handleApiAction(apiException.apiAction);
    }, [handleApiAction, logger]);

    const handleUnauthorizedException = useCallback((apiException: UnauthorizedException): void => {
        router.push('/static/?route=bot_error');
        logger.alert(apiException.message);
    }, []);

    const handleAccessDeniedException = useCallback((apiException: AccessDeniedException): void => {
        logger.alert(apiException.message);
    }, []);

    const handleGeneralError = useCallback((error: Error) => {
        router.push('/static/?route=bot_error');
        logger.error(`An error occurred. ${error.message}.`, error);
    }, []);

    const handleApiError = useCallback(async (error: any) => {
        if (error instanceof ApiException) {
            handleApiException(error);
        } else if (error instanceof UnauthorizedException) {
            handleUnauthorizedException(error);
        } else if (error instanceof AccessDeniedException) {
            handleAccessDeniedException(error);
        } else {
            handleGeneralError(error);
        }
    }, [handleApiException, handleUnauthorizedException, handleAccessDeniedException, handleGeneralError]);

    useEffect(() => {
        if ((isHomePath || !channel) && !pathname.endsWith('transactions') && !pathname.includes('manage-currency')) {
            backButton.hide();
            setSecondaryButtonParams('home');
        } else if ((!isHomePath && channel) || pathname.endsWith('transactions') || pathname.includes('manage-currency')) {
            backButton.show();
            secondaryButton.show();
        }
    }, [channel, pathname, isHomePath, setMainButtonParams, setSecondaryButtonParams, secondaryButton, backButton]);

    // useEffect(() => {
    //     if (isProceed) {
    //         setMainButtonParams('confirm');
    //     }
    // }, [isProceed, channel, setMainButtonParams]);

    // useEffect(() => {
    //     if (channel && channel !== Channel.TopUp) {
    //         if (isEnoughBalance === null) {
    //             mainButton.disable();
    //         } else if (isEnoughBalance === false) {
    //             setMainButtonParams('not_enough_balance');
    //         } else {
    //             setMainButtonParams(channel);
    //         }
    //     } else {
    //         setMainButtonParams('menu');
    //     }
    // }, [channel, isEnoughBalance, setMainButtonParams]);

    // useEffect(() => {
    //     if (isProcessing) {
    //         setMainButtonParams('loading');
    //         setSecondaryButtonParams('home');
    //     } else {
    //         mainButton.hideProgress();
    //         if (isCommitted) {
    //             setMainButtonParams('home');
    //         }
    //     }
    // }, [isProcessing, isCommitted, mainButton, setMainButtonParams, setSecondaryButtonParams]);

    useEffect(() => {
        if (i18n.isInitialized && i18n.language && user) {
            setMainButtonParams('menu');
            setSecondaryButtonParams('home');
        }
    }, [i18n.isInitialized, i18n.language, user]);

    const handleActivated = useCallback(() => {
        setIsActive(true);
    }, []);
    
    const handleDeactivated = useCallback(() => {
        setIsActive(false);
    }, []);

    const handleViewPortExpanded = useCallback((event: { isStateStable: boolean; viewportHeight: number; }) => {
        if (event.isStateStable && isInitialized) {
            webApp.ready();
            settingsButton.show();
        }
    }, [webApp, isInitialized, settingsButton]);

    useEffect(() => {
        if (!user) {
            if (webApp.isVersionAtLeast(AppConfig.telegram.minRequiredVersionFor.CLOUD_STORAGE)) {
                void restoreCachedData();
            }
            handleAuthorize();
        }
    }, [webApp, user]);

    useEffect(() => {
        logger.setLogPrefix('AppProvider');
        webApp.onEvent('activated', handleActivated);
        webApp.onEvent('deactivated', handleDeactivated);
        webApp.onEvent('viewportChanged', handleViewPortExpanded);

        return () => {
            webApp.offEvent('activated', handleActivated);
            webApp.offEvent('deactivated', handleDeactivated);
            webApp.offEvent('viewportChanged', handleViewPortExpanded);
        }
    }, [webApp, handleActivated, handleDeactivated, handleViewPortExpanded]);

    return (
        <AppContext.Provider value={{
            logger,
            setStep,
            step,
            login,
            isAuthorized,
            setIsInitialized,
            isInitialized,
            setIsActive,
            isActive,
            user,
            userId,
            isHomePath,
            handleApiException,
            handleApiError,
            handleApiAction,
            handleGeneralError,
            handleAccessDeniedException,
            handleUnauthorizedException,
        }}>
            {children}
        </AppContext.Provider>
    )
};

