'use client';

import { useCallback, useMemo, useState } from "react";
import { IBottomButton, IBottomButtonParams } from "@/types/telegram";
import { t } from "i18next";

export type ShowMainButtonFunction = () => void;
export type HideMainButtonFunction = () => void;
export type SetMainButtonParamsFunction = (params: IBottomButtonParams) => IBottomButton;
export type EnableMainButtonFunction = () => void;
export type DisableMainButtonFunction = () => void;
export type ShowMainButtonProgressFunction = () => IBottomButton;
export type HideMainButtonProgressFunction = () => IBottomButton;
export type StoreMainButtonParamsFunction = () => IBottomButton;
export type RestoreMainButtonParamsFunction = () => boolean;

const useMainButton = (mainButton: IBottomButton): {
    show: ShowMainButtonFunction;
    hide: HideMainButtonFunction;
    setParams: SetMainButtonParamsFunction;
    enable: EnableMainButtonFunction;
    disable: DisableMainButtonFunction;
    showProgress: ShowMainButtonProgressFunction;
    hideProgress: HideMainButtonProgressFunction;
    storeParams: StoreMainButtonParamsFunction;
    restoreParams: RestoreMainButtonParamsFunction;
} => {
    const [storedParams, setStoredParams] = useState<IBottomButtonParams | null>(null);

    const show = useCallback(() => {
        if (!mainButton.isVisible) {
            mainButton.show();
        }
    }, [mainButton]);

    const hide = useCallback(() => {
        if (mainButton.isVisible) {
            mainButton.hide();
        }
    }, [mainButton]);

    const enable = useCallback(() => {
        if (!mainButton.isActive) {
            mainButton.enable();
        }
    }, [mainButton]);

    const disable = useCallback(() => {
        if (mainButton.isActive) {
            mainButton.disable();
        }
    }, [mainButton]);

    const setParams = useCallback((params: IBottomButtonParams): IBottomButton => {
        return mainButton.setParams(params);
    }, [mainButton]);

    const showProgress = useCallback((): IBottomButton => {
        if (!mainButton.isProgressVisible) {
            mainButton.showProgress(false);
        }

        return mainButton;
    }, [mainButton]);

    const hideProgress = useCallback((): IBottomButton => {
        if (mainButton.isProgressVisible) {
            mainButton.hideProgress();
        }

        return mainButton;
    }, [mainButton]);

    const storeParams = useCallback((): IBottomButton => {
        setStoredParams({
            text: mainButton.text,
            text_color: mainButton.textColor,
            color: mainButton.color,
            is_active: mainButton.isActive,
            is_visible: mainButton.isVisible,
            has_shine_effect: mainButton.hasShineEffect,
        });

        return mainButton;
    }, [mainButton]);

    const restoreParams = useCallback((): boolean => {
        if (storedParams) {
            mainButton.setParams(storedParams);
            setStoredParams(null);
            return true;
        } else {
            return false;
        }
    }, [storedParams, mainButton]);

    return useMemo(() => ({ show, hide, setParams, enable, disable, showProgress, hideProgress, storeParams, restoreParams }), [mainButton]);
};

export default useMainButton;