import { DrawerContent, DrawerFooter } from "./ui/drawer";
import Image from 'next/image';
import { t } from "i18next";
import { Button } from "./ui/button";
import { SendIcon } from "lucide-react";
import { ScrollArea } from "./ui/scroll-area";
import Link from "next/link";

const MainMenu: React.FC<{ onSelect: (menu: string) => void }> = ({ onSelect }) => {
    return (
        <DrawerContent className="h-[85vh] bg-footer">
            <div>
                <div className="p-4">
                    <div className="relative mb-6 bg-[url('/assets/mountains.svg')] bg-zinc-900 bg-cover bg-center text-white rounded-lg p-4 w-full h-[120px]">
                        <div className="relative z-10 text-left mt-2">
                            <h2 className="text-2xl font-bold">Get rewarded up<br />to 4030 USD!</h2>
                        </div>
                        <div className="absolute inset-0 flex items-center justify-end z-20">
                            <Image
                                src="/assets/banner/1.webp"
                                alt="Banner Image"
                                width={150}
                                height={100}
                                className="object-contain"
                            />
                        </div>
                    </div>
                    <ScrollArea>
                        <div className="flex flex-row w-full space-x-2 mb-3">
                            <Button
                                variant="outline"
                                className="w-full flex flex-row items-center justify-between rounded-xl text-sm px-4 py-6"
                                onClick={() => onSelect('top-up')}
                            >
                                <div className="flex flex-row items-center space-x-2">
                                    <Image
                                        src="/assets/icons/invite.svg"
                                        alt={t('top_up')}
                                        height={24}
                                        width={24}
                                    />
                                    <h5 className="text-sm font-semibold">{t('top_up')}</h5>
                                </div>
                                <i className="icon icon-chevron-right" />
                            </Button>
                            <Button
                                variant="outline"
                                className="w-full flex flex-row items-center justify-between rounded-xl text-sm px-4 py-6"
                                onClick={() => onSelect('token-buy')}
                            >
                                <div className="flex flex-row items-center space-x-2">
                                    <Image
                                        src="/assets/icons/crypto.svg"
                                        alt={t('token_buy')}
                                        height={24}
                                        width={24}
                                    />
                                    <h5 className="text-sm font-semibold">{t('token_buy')}</h5>
                                </div>
                                <i className="icon icon-chevron-right" />
                            </Button>
                        </div>
                        <div className="grid grid-cols-3 gap-2 mb-4">
                            <div className="flex flex-col px-4 py-2 space-y-1 items-center justify-center rounded-xl border border-input bg-background hover:bg-accent hover:text-accent-foreground" onClick={() => onSelect('swap')}>
                                <Image
                                    src="/assets/icons/exchange.svg"
                                    alt={t('swap')}
                                    height={24}
                                    width={24}
                                />
                                <h4 className="text-sm font-semibold text-center">
                                    {t('swap')}
                                </h4>
                            </div>
                            <div className="flex flex-col px-4 py-2 space-y-1 items-center justify-center rounded-xl border border-input bg-background hover:bg-accent hover:text-accent-foreground" onClick={() => onSelect('transfer')}>
                                <SendIcon size={24} />
                                <h4 className="text-sm font-semibold text-center">
                                    {t('transfer')}
                                </h4>
                            </div>
                            <div
                                className="flex flex-col px-4 py-2 space-y-1 items-center justify-center rounded-xl border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                                onClick={() => onSelect('withdraw')}
                            >
                                <Image
                                    src="/assets/icons/exchange.svg"
                                    alt={t('withdraw')}
                                    height={24}
                                    width={24}
                                />
                                <h4 className="text-sm font-semibold text-center">
                                    {t('withdraw')}
                                </h4>
                            </div>
                        </div>
                        
                        <div className="grid grid-cols-3 gap-2">
                            <div
                                className="flex flex-col px-4 py-2 space-y-1 items-center justify-center rounded-xl border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                                onClick={() => onSelect('token-staking')}
                            >
                                <Image
                                    src="/assets/icons/staking.svg"
                                    alt={t('token_staking')}
                                    height={24}
                                    width={24}
                                />
                                <h4 className="text-sm font-semibold text-center">
                                    {t('token_staking')}
                                </h4>
                            </div>
                            <div
                                className="flex flex-col px-4 py-2 space-y-1 items-center justify-center rounded-xl border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                                onClick={() => onSelect('token-sell')}
                            >
                                <Image
                                    src="/assets/icons/exchange.svg"
                                    alt={t('token_sell')}
                                    height={24}
                                    width={24}
                                />
                                <h4 className="text-sm font-semibold text-center">
                                    {t('token_sell')}
                                </h4>
                            </div>
                            <div
                                className="flex flex-col px-4 py-2 space-y-1 items-center justify-center rounded-xl border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                                onClick={() => onSelect('referral')}
                            >
                                <Image
                                    src="/assets/icons/invite.svg"
                                    alt={t('referral')}
                                    height={24}
                                    width={24}
                                />
                                <h4 className="text-sm font-semibold text-center">
                                    {t('referral_program')}
                                </h4>
                            </div>
                        </div>
                    </ScrollArea>
                </div>
                <Button asChild>
                    <Link href="/test">TEST</Link>
                </Button>
            </div>
            <DrawerFooter className="p-2">
                <Button
                    variant="ghost"
                    size="sm"
                    className="text-xs text-muted-foreground hover:text-foreground"
                    onClick={() => onSelect('')}
                >
                    {t('home')}
                </Button>
                <Button
                    variant="ghost"
                    size="sm"
                    className="text-xs text-muted-foreground hover:text-foreground"
                    onClick={() => onSelect('transactions')}
                >
                    {t('my_transactions')}
                </Button>
            </DrawerFooter>
        </DrawerContent>
    );
};

export default MainMenu;