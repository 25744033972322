'use client';

import { IBackButton } from "@/types/telegram";
import { useMemo, useCallback } from "react";

export type ShowBackButtonFunction = () => void;
export type HideBackButtonFunction = () => void;

const useBackButton = (backButton: IBackButton): {
    hide: HideBackButtonFunction;
    show: ShowBackButtonFunction;
} => {
    const show = useCallback(() => {
        if (!backButton.isVisible) backButton.show();
    }, [backButton]);

    const hide = useCallback(() => {
        if (backButton.isVisible) backButton.hide();
    }, [backButton]);

    return useMemo(() => ({ show, hide }), [backButton]);
};

export default useBackButton;
