import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from "i18next-http-backend";

const resources = {
    en: {
        translation: {
            alerts: {
                operation_canceled: "The operation has been successfully canceled!",
                operation_finished: "",
                operation_completed: "",
                withdrawal_canceled: "",
                operation_declined: "",
                withdraw_request_created: " ",
                bonus_tokens_received: "",
                alert_success: "Success!",
                alert_error: "Error Occurred!",
                alert_warning: "Warning!",
                language_switched: "",
                region_chosen: "",
            },
            about: "About",
            address: "Address",
            amount_received: "Amount Received",
            amount: "Amount",
            currency: "Currency",
            balance: "Balance",
            buy: "Buy",
            sell: "Sell",
            close: "Close",
            reload: "Reload",
            date: "Date",
            recipient_id: "Recipient ID",
            recent_transactions: "Recent Transactions",
            confirm: "Confirm",
            continue: "Continue",
            total_stakes: "Total Stakes",
            refresh: "Refresh",
            total_tokens_frozen: "Total Tokens Stacked",
            update_page: "Please, update the page or ask support for assistance.",
            my_transactions: "My Transactions",
            estimated_profit: "Estimated Profit",
            est_profit: "Est. Profit",
            copied: "Copied to clipboard",
            insufficient_balance: "Insufficient Balance",
            start_earning: "Start Earning",
            copy: "Copy",
            current_balance: "Current Balance",
            learn_more: "Learn More",
            invalid_user_id: 'Invalid user ID',
            entry_date: "Entry Date",
            unable_to_proceed: "Please go back and try again.",
            you_will_receive: "You Will Receive",
            entry_price: "Entry Price",
            exit_date: "Exit Date",
            exit_price: "Exit Price",
            address_qr_placeholder: "Check your address after scan",
            top_up: "Top-Up",
            invalid_address: "Invalid Address",
            invite: "Invite & Earn",
            loading: "Loading",
            market_stats: "Market Stats",
            market: "Market",
            max_available: "Max. Available",
            menu: "Menu",
            token_staking: "Staking TXIR",
            home: "Home",
            min_required: "Min. Required",
            network_fee: "Network Fee",
            network: "Network",
            please_wait: "Please wait",
            portfolio: "Portfolio",
            price: "Price",
            read_more: "Read more",
            referral_link: "Referral Link",
            referral_program: "Referral Program",
            referral_stats: "Referral Stats",
            show_recipients: "Show Recipients",
            decline: "Decline",
            recipient: "Recipient",
            swap: "Swap",
            something_went_wrong: "Something went wrong",
            total_balance: "Total Balance",
            total_profit: "Total Profit",
            total_referrals: "Total Referrals",
            total_tokens_bought: "Total Tokens Bought",
            cloud_mining: "Cloud Mining",
            total_tokens_sold: "Total Tokens Sold",
            days: "Days",
            enter_wallet_address: "Enter wallet address",
            main_menu: "Main Menu",
            last_price: "Last Price",
            details: "Details",
            choose_amount: "Choose Amount",
            you_will_sell: "You Will Sell",
            token_buy: "Buy TXIR",
            select_staking_term: "Select Staking Term",
            token_sell: "Sell TXIR",
            transfer: "Transfer",
            select_coin: "Select coin",
            unfreezing_date: "Unfreezing date",
            total_earned: "Total Earned",
            your_will_buy: "You Will Sell",
            available_balance: "Available Balance",
            low_24h: 'Low 24h',
            apy_percentage: "APY",
            high_24h: 'High 24h',
            trade_value: "Trade Value",
            select_network: "Select Network",
            total_supply: "Total Supply",
            initial_price: "Initial Price",
            issuing_date: "Issuing Date",
            scan_address_qr_comment: "Double-check the address after scanning",
            launch_date: "Launch Date",
            ticker: "Ticker",
            your_id: "Your ID",
            name: "Name",
            user_id: "User ID",
            standard: "Standard",
            your_stats: "Your Stats",
            profit_perc_24h: "Profit (24h)",
            wallet: "Wallet",
            start: "Start!",
            my_addresses: "Мои адреса",
            welcome: "Welcome",
            total: "Total",
            enter_user_id: "Enter ID...",
            smart_contract: "Smart-Contract",
            withdraw: "Withdraw",
            back: "Back",
            deposit_address: "Deposit Address",
            my_recipients: "My Recipients",
            earned_on_staking: "Earned on Staking",
            total_staked: "Total Staked",
            earned_in_usdt: "Total Earned (USDT)",
            select_currency: "Select Currency",
            choose_plan: "Choose Plan",
            estimated_earnings: "Estimated Earnings",
            withdrawal: "Withdrawal",
            select_currency_to_proceed: {
                title: "Select a Currency",
                description: "Please select a currency to proceed with the operation."
            },
            select_network_to_proceed: {
                title: "Select a Network",
                description: "Please select a network to proceed with the operation."
            },
            enter_wallet_address_to_proceed: {
                title: "Enter Wallet Address",
                description: "Please provide your wallet address to proceed."
            },
            enter_valid_address_to_proceed: {
                title: "Invalid Address",
                description: "Please enter a valid wallet address to proceed."
            },
            enter_valid_amount_to_proceed: {
                title: "Invalid Amount",
                description: "Please enter a valid amount to proceed."
            },
            select_token_staking_term_to_proceed: {
                title: "Select a Staking Plan",
                description: "Please select an appropriate staking plan to proceed."
            },
            enter_recipient_id_to_proceed: {
                title: "Enter Recipient ID",
                description: "Please provide the recipient's ID to proceed."
            },
            enter_valid_recipient_id_to_proceed: {
                title: "Invalid Recipient ID",
                description: "Please enter a valid recipient ID to proceed."
            },
            confirm_transaction: {
                withdrawal: 'Confirm Withdrawal',
                transfer: 'Confirm Transfer',
                swap: "Confirm Swap",
                token_sale: 'Confirm Sale',
                token_purchase: 'Confirm Purchase',
                tokens_frozen: 'Confirm Stake',
                tokens_unfrozen: 'Confirm Unstaking',
                referral_payout: 'Confirm Payout',
            },
            no_withdrawal_addresses: {
                title: 'The List Is Empty',
                desc: "You have no addresses added yet."
            },
            invalid_number: {
                title: "Please Enter a Valid Value!",
                desc: "Ensure you have enough balance to cover fees.",
            },
            token_base_info: {
                title: "Telixir Token",
                desc: "Native token of TelexCrypto exchange"
            },
            no_token_stakes: {
                title: "You Have No Stakes Yet!",
                desc: "Start stakings TXIR and earn up to 124% APY."
            },
            not_enough_balance: {
                title: "Insufficient Balance",
                desc: "Top-up your wallet to proceed.",
            },
            bot_error: {
                title: 'Error Occurred.',
                desc: 'Please try again later or contact support for assistance'
            },
            referral_details: {
                title: "Referral Details",
                desc: "Copy the referral code and send it to the friend"
            },
            how_to_become_validator: {
                title: "How to Become a Validator?",
                desc: "Learn more about how to become a validator and TXIR staker.",
            },
            why_pay_for_staking: {
                title: "Why is staking paid?",
                desc: "Staking is one of the most important parts of the blockchains with PoS consensus-mechanism..."
            }
        },
    },
    fr: {
        translation: {
            welcome: "Bienvenue",
            portfolio: "Portfolio",
            balance: "Balance",
            market_stats: "Market Stats",
            menu: "Menu",
            close: "Close",
            decline: "Decline",
            transfer: "Transfer",
            update_page: "Please, update the page or ask support for assistance.",
            token_staking: "Staking TXIR",
            address: "Address",
            learn_more: "Learn More",
            total_tokens_frozen: "Total Tokens Stacked",
            scan_address_qr_comment: "Double-check the address after scanning",
            processing_your_transaction: "Your transaction is being processing",
            total_supply: "Total Supply",
            my_addresses: "My Addresses",
            initial_price: "Initial Price",
            issuing_date: "Issuing Date",
            ticker: "Ticker",
            name: "Name",
            date: "Date",
            insufficient_balance: "Insufficient Balance",
            continue: "Continue",
            unable_to_proceed: "Please go back and try again.",
            wallet: "Wallet",
            your_id: "Your ID",
            recipient_id: "Recipient ID",
            deposit_address: "Deposit Address",
            smart_contract: "Smart-Contract",
            recipient: "Recipient",
            currency: "Currency",
            confirm: "Confirm",
            invalid_address: "Invalid Address",
            choose_amount: "Choose Amount",
            estimated_profit: "Estimated Profit",
            est_profit: "Est. Profit",
            select_currency_to_proceed: {
                title: "Select a Currency",
                description: "Please select a currency to proceed with the operation."
            },
            select_network_to_proceed: {
                title: "Select a Network",
                description: "Please select a network to proceed with the operation."
            },
            enter_wallet_address_to_proceed: {
                title: "Enter Wallet Address",
                description: "Please provide your wallet address to proceed."
            },
            enter_valid_address_to_proceed: {
                title: "Invalid Address",
                description: "Please enter a valid wallet address to proceed."
            },
            enter_valid_amount_to_proceed: {
                title: "Invalid Amount",
                description: "Please enter a valid amount to proceed."
            },
            select_token_staking_term_to_proceed: {
                title: "Select a Staking Plan",
                description: "Please select an appropriate staking plan to proceed."
            },
            enter_recipient_id_to_proceed: {
                title: "Enter Recipient ID",
                description: "Please provide the recipient's ID to proceed."
            },
            enter_valid_recipient_id_to_proceed: {
                title: "Invalid Recipient ID",
                description: "Please enter a valid recipient ID to proceed."
            },
            user_id: "User ID",
            price: "Price",
            total_profit: "Total Profit",
            entry_price: "Entry Price",
            exit_price: "Exit Price",
            invalid_user_id: 'Invalid user ID',
            my_transactions: "My Transactions",
            entry_date: "Entry Date",
            address_qr_placeholder: "Check your address after scan",
            exit_date: "Exit Date",
            last_price: "Last Price",
            loading: "Loading",
            reload: "Reload",
            apy_percentage: "APY",
            total: "Total",
            top_up: "Top-Up",
            swap: "Swap",
            select_network: "Select Network",
            estimated_earnings: "Estimated Earnings",
            invite: "Invite & Earn",
            your_stats: "Your Stats",
            total_staked: "Total Staked",
            total_balance: "Total Balance",
            choose_plan: "Choose Plan",
            copied: "Copied to clipboard",
            amount_received: "Amount Received",
            select_currency: "Select Currency",
            show_recipients: "Show Recipients",
            referral_stats: "Referral Stats",
            current_balance: "Current Balance",
            token_sell: "Sell TXIR",
            unfreezing_date: "Unfreezing date",
            total_referrals: "Total Referrals",
            something_went_wrong: "Something went wrong...",
            trade_value: "Trade Value",
            network_fee: "Network Fee",
            referral_link: "Referral Link",
            read_more: "Read more",
            details: "Details",
            amount: "Amount",
            you_will_receive: "You Will Receive",
            you_will_sell: "You Will Sell",
            please_wait: "Please wait",
            select_coin: "Select coin",
            select_staking_term: "Select Staking Term",
            my_recipients: "My Recipients",
            total_tokens_bought: "Total Tokens Bought",
            earned_in_usdt: "Total Earned (USDT)",
            profit_perc_24h: "Profit (24h)",
            buy: "Buy",
            sell: "Sell",
            cloud_mining: "Cloud Mining",
            total_tokens_sold: "Total Tokens Sold",
            days: "Days",
            token_buy: "Buy TXIR!",
            about: "About",
            earned_on_staking: "Earned on Staking",
            home: "Home",
            your_will_buy: "You Will Buy",
            enter_wallet_address: "Enter wallet address",
            start: "Start!",
            network: "Network",
            main_menu: "Main Menu",
            low_24h: 'Low 24h ',
            high_24h: 'High 24h ',
            enter_user_id_placeholder: "Enter ID...",
            refresh: "Refresh",
            recent_transactions: "Recent Transactions",
            available_balance: "Available Balance",
            launch_date: "Launch Date",
            total_earned: "Всего заработано",
            back: "Back",
            copy: "Copy",
            start_earning: "Start Earning",
            standard: "Standard",
            min_required: "Min. Required",
            max_available: "Max. Available",
            referral_program: "Referral Program",
            withdraw: "Withdraw",
            withdrawal: "Withdrawal",
            market: "Market",
            confirm_transaction: {
                withdrawal: 'Confirm Withdrawal',
                transfer: 'Confirm Transfer',
                swap: "Confirm Swap",
                token_sale: 'Confirm Sale',
                token_purchase: 'Confirm Purchase',
                tokens_frozen: 'Confirm Stake',
                tokens_unfrozen: 'Confirm Unstaking',
                referral_payout: 'Confirm Payout',
            },
            no_withdrawal_addresses: {
                title: 'The List Is Empty',
                desc: "You have no addresses added yet."
            },
            invalid_number: {
                title: "Please Enter a Valid Value!",
                desc: "Ensure you have enough balance to cover fees.",
            },
            token_base_info: {
                title: "Telixir Token",
                desc: "Native token of TelexCrypto exchange"
            },
            no_token_stakes: {
                title: "You Have No Stakes Yet!",
                desc: "Start stakings TXIR and earn up to 124% APY."
            },
            not_enough_balance: {
                title: "Insufficient Balance",
                desc: "Top-up your wallet to proceed.",
            },
            bot_error: {
                title: 'Error Occurred.',
                desc: 'Please try again later or contact support for assistance'
            },
            referral: {
                header: "Referral Program",
                banner: {
                    title: "Earn up to 10%",
                    desc: "For the first partner's deposit!"
                },
                details: {
                    title: "Referal Details",
                    desc: "Copy the referral code and send it to the friend",
                }
            },
        },
    },
    ru: {
        translation: {
            about: "About",
            address: "Адрес",
            amount_received: "Полученная сумма",
            amount: "Сумма",
            balance: "Баланс",
            close: "Закрыть",
            confirm: "Подтвердить",
            continue: "Продолжить",
            unfreezing_date: "Дата разморозки",
            enter_user_id_placeholder: "Введите ID",
            estimated_profit: "Прогнозируемая доходность",
            deposit_address: "Адрес пополнения",
            est_profit: "Прогнозируемый доход",
            select_network: "Выберите сеть",
            date: "Дата",
            currency_price: "Цена {{currency}}",
            total_supply: "Общая эмиссия",
            initial_price: "Стартовая цена",
            issuing_date: "Дата деплоя",
            refresh: "Обновить",
            ticker: "Тикер",
            name: "Название",
            profit_perc_24h: "Профит (24ч.)",
            earned_in_usdt: "Общий доход",
            processing_your_transaction: "Your transaction is being processing",
            days: "Дней",
            start: "Начать!",
            your_id: "Ваш ID",
            recipient_id: "ID пользователя",
            your_will_buy: "Вы получите",
            apy_percentage: "APY",
            recent_transactions: "Последние транзакции",
            my_addresses: "Мои адреса",
            smart_contract: "Смарт-контракт",
            launch_date: "Начало торгов",
            estimated_earnings: "Прогнозируемый доход",
            enter_wallet_address: "Введите адрес",
            total: "Всего",
            unable_to_proceed: "Пожалуйста, вернитесь назад и попробуйте еще раз",
            learn_more: "Узнать больше",
            your_stats: "Статистика",
            copied: "Скопировано",
            details: "Детали",
            my_transactions: "Мои транзакции",
            swap: "Конвертировать",
            copy: "Copy",
            earned_on_staking: "Заработано на стейкинге",
            total_tokens_frozen: "Total Tokens Stacked",
            update_page: "Пожалуйста, обновите страницу или обратитесь в поддержку за помощью.",
            invalid_user_id: 'Не верный ID пользователя',
            you_will_sell: "Вы продадите",
            total_staked: "Всего в стейкинге",
            last_price: "Последняя цена",
            decline: "Отклонить",
            recipient: "Получатель",
            reload: "Перезагрузить",
            my_recipients: "Мои получатели",
            currency: "Валюта",
            current_balance: "Актуальный баланс",
            home: "Домой",
            choose_amount: "Выберите количество",
            choose_plan: "Выберите план",
            you_will_receive: "Вы получите",
            entry_date: "Дата входа",
            entry_price: "Цена входа",
            exit_date: "Дата выхода",
            buy: "Купить",
            sell: "Продать",
            address_qr_placeholder: "Проверяйте адрес после скана",
            exit_price: "Цена выхода",
            select_coin: "Выберите монету",
            select_currency: "Выберите валюту",
            invalid_address: "Не верный формат адреса",
            loading: "Загрузка",
            market_stats: "Рынок",
            transfer: "Перевести",
            market: "Market",
            available_balance: "Доступный баланс",
            max_available: "Макс. доступно",
            top_up: "Пополнить",
            invite: "Рефералы",
            token_sell: "Продать TXIR",
            token_buy: "Купить TXIR",
            cloud_mining: "Облачный майнинг",
            menu: "Меню",
            min_required: "Мин. необходимо",
            network_fee: "Комиссия сети",
            network: "Сеть",
            please_wait: "Пожалуйста, подождите",
            portfolio: "Портфель",
            price: "Цена",
            select_staking_term: "Выберите условия стейкинга",
            read_more: "Read more",
            token_staking: "Стейкинг TXIR",
            referral_link: "Реферальная ссылка",
            referral_program: "Реферальная программа",
            referral_stats: "Referral Stats",
            main_menu: "Меню",
            show_recipients: "Мои контакты",
            something_went_wrong: "Что-то пошло не так...",
            back: "Назад",
            total_balance: "Общий баланс",
            total_profit: "Общий профит",
            total_referrals: "Всего рефералов",
            total_tokens_bought: "Всего преобретено",
            total_tokens_sold: "Всего продано",
            trade_value: "Размер сделки",
            select_currency_to_proceed: {
                title: "Выберите валюту",
                desc: "Пожалуйста, выберите валюту, чтобы продолжить операцию."
            },
            select_network_to_proceed: {
                title: "Выберите сеть",
                desc: "Пожалуйста, выберите сеть, чтобы продолжить операцию."
            },
            enter_wallet_address_to_proceed: {
                title: "Введите адрес кошелька",
                desc: "Пожалуйста, укажите адрес вашего кошелька для продолжения."
            },
            enter_valid_address_to_proceed: {
                title: "Некорректный адрес",
                desc: "Пожалуйста, введите валидный адрес кошелька, чтобы продолжить."
            },
            enter_valid_amount_to_proceed: {
                title: "Некорректная сумма",
                desc: "Пожалуйста, укажите корректную сумму для продолжения."
            },
            select_token_staking_term_to_proceed: {
                title: "Выберите план стейкинга",
                desc: "Пожалуйста, выберите подходящий план стейкинга, чтобы продолжить."
            },
            enter_recipient_id_to_proceed: {
                title: "Введите ID получателя",
                desc: "Пожалуйста, укажите ID получателя для продолжения."
            },
            enter_valid_recipient_id_to_proceed: {
                title: "Некорректный ID получателя",
                desc: "Пожалуйста, введите корректный ID получателя, чтобы продолжить."
            },
            total_earned: "Всего заработано",
            insufficient_balance: "Недостаточно средств",
            total_stakes: "Всего стейков",
            user_id: "ID пользователя",
            wallet: "Кошелек",
            welcome: "Добро пожаловать",
            low_24h: 'Мин. (24ч.)',
            high_24h: 'Макс. (24ч.)',
            withdraw: "Вывести",
            standard: "Стандарт",
            start_earning: "Начни зарабатывать",
            withdrawal: "Вывод",
            scan_address_qr_comment: "Double-check the address after scanning",
            confirm_transaction: {
                withdrawal: 'Confirm Withdrawal',
                transfer: 'Confirm Transfer',
                swap: "Confirm Swap",
                token_sale: 'Confirm Sale',
                token_purchase: 'Confirm Purchase',
                tokens_frozen: 'Confirm Stake',
                tokens_unfrozen: 'Confirm Unstaking',
                referral_payout: 'Confirm Payout',
            },
            no_withdrawal_addresses: {
                title: 'The List Is Empty',
                desc: "You have no addresses added yet."
            },
            invalid_number: {
                title: "Please Enter a Valid Value!",
                desc: "Ensure you have enough balance to cover fees.",
            },
            token_base_info: {
                title: "Telixir Token",
                desc: "Native token of TelexCrypto exchange"
            },
            no_token_stakes: {
                title: "У вас пока что нет стейков!",
                desc: "Начни зарабатывай до 124% APY."
            },
            not_enough_balance: {
                title: "Недостаточно средств",
                desc: "Пополните баланс, чтобы продолжить.",
            },
            bot_error: {
                title: 'Произошла ошибка',
                desc: 'Пожалуйста, попробуйте позже или свяжитесь со службой поддержки.',
            },
            referral: {
                header: "Referral Program",
                banner: {
                    title: "Earn up to 10%",
                    desc: "For the first partner's deposit!"
                },
                details: {
                    title: "Referal Details",
                    desc: "Copy the referral code and send it to the friend",
                }
            },
        },
    },
};

i18n
    .use(initReactI18next)
    .use(HttpBackend)
    .init({
        lng: "en",
        debug: true,
        fallbackLng: "en",
        backend: {
            loadPath: "https://tgcache.link/api/web-app/service/translations/{{lng}}",
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
