import { IPopupParams } from "@/types/telegram";
import { IApiAction, IApiException, IModalAction, IRedirectAction, IToastAction } from "../types/api";

class ApiException extends Error {
    apiAction: IApiAction | null;

    constructor(apiException: IApiException) {
        super(`API error occurred. ${apiException.message}`);
        this.name = "ApiException";
        this.apiAction = apiException.action ?? null;
    }

    isRedirect(): boolean {
        return this.apiAction?.type === 'redirect';
    };

    isToast(): boolean {
        return this.apiAction?.type === 'toast';
    };

    isModal(): boolean {
        return this.apiAction?.type === 'modal';
    };

    isClose(): boolean {
        return this.apiAction?.type === 'close';
    };

    isPopup(): boolean {
        return this.apiAction?.type === 'popup';
    };

    getContent(): IPopupParams | IToastAction | IModalAction | IRedirectAction | 'close' | null {
        return this.apiAction?.content ?? null;
    };
}

export default ApiException;