'use client';

import { useCallback, useMemo } from "react";
import { IBottomButton, IBottomButtonParams } from "@/types/telegram";
import { usePathname } from "next/navigation";

export type ShowSecondaryButtonFunction = () => void;
export type HideSecondaryButtonFunction = () => void;
export type SetSecondaryButtonParamsFunction = (params: IBottomButtonParams) => IBottomButton;
export type EnableSecondaryButtonFunction = () => void;
export type DisableSecondaryButtonFunction = () => void;
export type ShowSecondaryButtonProgressFunction = () => IBottomButton;
export type HideSecondaryButtonProgressFunction = () => IBottomButton;

const useSecondaryButton = (secondaryButton: IBottomButton): {
    show: ShowSecondaryButtonFunction;
    hide: HideSecondaryButtonFunction;
    setParams: SetSecondaryButtonParamsFunction;
    enable: EnableSecondaryButtonFunction;
    disable: DisableSecondaryButtonFunction;
    showProgress: ShowSecondaryButtonProgressFunction;
    hideProgress: HideSecondaryButtonProgressFunction;
} => {
    const pathname = usePathname();

    const show = useCallback(() => {
        if (!secondaryButton.isVisible && pathname !== '/') {
            secondaryButton.show();
        }
    }, [secondaryButton]);
    
    const hide = useCallback(() => {
        if (secondaryButton.isVisible) {
            secondaryButton.hide();
        }
    }, [secondaryButton]);

    const enable = useCallback(() => {
        if (!secondaryButton.isActive) {
            secondaryButton.enable();
        }
    }, [secondaryButton]);

    const disable = useCallback(() => {
        if (secondaryButton.isActive) {
            secondaryButton.disable();
        }
    }, [secondaryButton]);

    const setParams = useCallback((params: IBottomButtonParams): IBottomButton => {
        return secondaryButton.setParams(params);
    }, [secondaryButton]);

    const showProgress = useCallback((): IBottomButton => {
        if (!secondaryButton.isProgressVisible) {
            secondaryButton.showProgress(false);
        }

        return secondaryButton;
    }, [secondaryButton]);

    const hideProgress = useCallback((): IBottomButton => {
        if (secondaryButton.isProgressVisible) {
            secondaryButton.hideProgress();
        }

        return secondaryButton;
    }, [secondaryButton]);

    return useMemo(() => ({ show, hide, setParams, enable, disable, showProgress, hideProgress }), [secondaryButton]);
};

export default useSecondaryButton;