'use client';

import AccessDeniedException from '@/exceptions/AccessDeniedException';
import ApiException from '@/exceptions/ApiException';
import UnauthorizedException from '@/exceptions/UnauthorizedException';
import { logError } from '@/lib/actions';
import LoggerService from '@/services/LoggerService';
import { IApiAction, IBotUser } from '@/types/api';
import { createContext, useContext } from 'react';

interface AppContextType {
    isHomePath: boolean;
    user: IBotUser | null;
    userId: number;
    login: (user: IBotUser) => void;
    isAuthorized: boolean;
    isInitialized: boolean;
    setIsInitialized: (isInitialized: boolean) => void;
    setIsActive: (isActive: boolean) => void;
    isActive: boolean;
    logger: LoggerService;
    setStep: (step: number | null) => void;
    step: number | null;
    handleApiAction: (apiAction: IApiAction) => void;
    handleApiError: (error: any) => void;
    handleGeneralError: (error: Error) => void;
    handleApiException: (apiException: ApiException) => void;
    handleUnauthorizedException: (apiException: UnauthorizedException) => void;
    handleAccessDeniedException: (apiException: AccessDeniedException) => void;
};

export const AppContext = createContext<AppContextType | undefined>(undefined);

export const useApp = () => {
    const context = useContext(AppContext);
    if (!context) {
        logError('useApp must be used within a AppProvider');
        throw new Error('useApp must be used within a AppProvider');
    }
    return context;
};
