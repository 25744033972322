import { isFulfilled, isRejected, Middleware } from '@reduxjs/toolkit';
import { fetchBotUserWalletStats, fetchCryptoCurrencies } from './walletSlice';
import eventEmitter from '@/lib/eventEmitter';
import { logError } from '@/lib/actions';

const walletMiddleware: Middleware = (store) => (next) => (action) => {
    const result = next(action);

    if (isFulfilled(fetchCryptoCurrencies)(action)) {
        const cryptoCurrencies = action.payload;
        eventEmitter.emit('cryptoCurrenciesUpdated', cryptoCurrencies);
    } else if (isRejected(fetchCryptoCurrencies)(action)) {
        const error = action.error;
        logError(`walletMiddleware: failed on fetching crypto currencies. Error: ${error.message || 'Unknown error'}`, error);
    }

    return result;
};

export default walletMiddleware;
