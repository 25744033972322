'use client';

import { sendGetRequest, sendPostRequest } from "@/lib/actions";
import { ApiEndpoints } from "@/lib/appConfig";
import { handleApiResponse } from "@/lib/utils";
import { IApiException } from "@/types/api";
import { Locale } from "@/types/enums";

export const useUserProfile = (userId: number) => {
    const getTimeOffset = async (): Promise<{ time_offset: string }> => {
        const apiResponse = await sendGetRequest<{ time_offset: string }>(ApiEndpoints.userProfile.GET_TIME_OFFSET, null, userId);
        return handleApiResponse<{ time_offset: string }>(apiResponse);
    };

    const switchLanguage = async (locale: Locale): Promise<boolean> => {
        const apiResponse = await sendPostRequest<boolean | IApiException>(ApiEndpoints.userProfile.SWITCH_LANGUAGE, { language: locale }, userId);
        return handleApiResponse<boolean>(apiResponse);
    };

    const getSeedPhrase = async (): Promise<{ seed_phrase: string[] }> => {
        const apiResponse = await sendGetRequest<{ seed_phrase: string[] } | IApiException>(ApiEndpoints.userProfile.GET_SEED_PHRASE, null, userId);
        return handleApiResponse<{ seed_phrase: string[] }>(apiResponse);
    };

    const getReferralUrl = async (): Promise<{ referral_url: string }> => {
        const apiResponse = await sendGetRequest<{ referral_url: string } | IApiException>(ApiEndpoints.userProfile.GET_REFERRAL_URL, null, userId);
        return handleApiResponse<{ referral_url: string }>(apiResponse);
    };

    return { getTimeOffset, switchLanguage, getSeedPhrase, getReferralUrl }
};