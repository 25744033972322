import { IApiAction } from "@/types/api";

class UnauthorizedException extends Error {
    apiAction: IApiAction;

    constructor() {
        super(`User is not authorized.`);
        this.name = 'UnauthorizedException';

        this.apiAction = {
            type: 'redirect',
            content: {
                url: '/static/user-not-found'
            }
        };

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnauthorizedException);
        }
    }
}

export default UnauthorizedException;