'use client';

import { createContext, useContext } from 'react';
import { logError } from '../lib/actions';

interface LocaleContextType {
    locale: string;
    switchLanguage: (locale: string) => Promise<boolean>;
};

export const LocaleContext = createContext<LocaleContextType | undefined>(undefined);

export const useLocale = () => {
    const context = useContext(LocaleContext);
    if (!context) {
        logError('useLocale must be used within a LocaleProvider');
        throw new Error('useLocale must be used within a LocaleProvider');
    }
    return context;
};