'use client';

import { Button } from "./ui/button";
import { DialogClose, DialogDescription, DialogContent, DialogTitle, DialogHeader, DialogFooter } from "./ui/dialog";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import CopyField from "./CopyField";
import { useApp } from "@/contexts/app-context";
import { useLocale } from "@/contexts/locale-context";
import { FingerprintIcon } from "lucide-react";

type SettingsMenuProps = {
    userId: number;
};

export const SettingsMenu = ({ userId }: SettingsMenuProps) => {
    const { t } = useTranslation();
    const locales = ['en', 'de', 'fr', 'es', 'pt', 'it', 'cz', 'pl', 'tr', 'ru'];
    const { switchLanguage } = useLocale();
    
    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Settings</DialogTitle>
                <DialogDescription>
                    <FingerprintIcon />
                    <CopyField
                        label="ID"
                        rawValue={userId}
                        formattedValue={userId}
                    />
                </DialogDescription>
                <div>
                    <Button asChild>
                        {/* <Link href={`/${webApp.initDataUnsafe.user?.id}/referral`}>
                            Referral
                        </Link> */}
                    </Button>
                </div>
                <DialogFooter>
                    <DialogClose>
                        <Button>{t('close')}</Button>
                    </DialogClose>
                </DialogFooter>
            </DialogHeader>
        </DialogContent>
    )
};
