
import ApiException from "@/exceptions/ApiException";
import { sendGetRequest } from "@/lib/actions";
import { ApiEndpoints } from "@/lib/appConfig";
import { IApiException, ITransferRecipient } from "@/types/api";
import { ICloudMiningPlan, ICryptoFees, ITokenStakingTerm } from "@/types/crypto";
import { Channel, Currency, Network } from "@/types/enums";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadCryptoFees } from "./cryptoSlice";
import { RootState } from "./store";

export const fetchCryptoFees = createAsyncThunk<void, void, { state: RootState }>("transaction/fetchCryptoFees", async (_, { getState, dispatch }) => {
    const { channel, selectedCurrency, selectedNetwork } = getState().transaction;

    if (!channel || !selectedCurrency) return;

    await dispatch(
        loadCryptoFees({
            channel,
            currency: selectedCurrency,
            network: selectedNetwork,
        })
    );
});

type TransactionState = {
    channel: Channel | null;
    selectedCurrency: Currency | null;
    selectedCurrencyToBuy: Currency | null;
    selectedNetwork: Network | null;
    amount: number;
    percentage: number;
    amountToBuy: number;
    limits: { min: number, max: number};
    address: string | null;
    selectedRecipient: ITransferRecipient | null;
    selectedStakingTerm: ITokenStakingTerm | null;
    selectedCloudMininPlan: ICloudMiningPlan | null;
    status: {
        isEnoughBalance: boolean | null,
        isAmountValid: boolean | null,
        isRecipientIdValid: boolean | null,
        isAddressValid: boolean | null,
        isProceed: boolean,
        isConfirmed: boolean | null,
        isProcessing: boolean,
        isCommitted: boolean | null,
        isSuccessful: boolean | null,
    };
};

const initialState: TransactionState = {
    channel: null,
    selectedCurrency: null,
    selectedCurrencyToBuy: null,
    selectedNetwork: null,
    amount: 0,
    percentage: 0,
    amountToBuy: 0,
    limits: { min: 0, max: 0 },
    address: null,
    selectedRecipient: null,
    selectedStakingTerm: null,
    selectedCloudMininPlan: null,
    status: {
        isEnoughBalance: null,
        isAmountValid: null,
        isAddressValid: null,
        isRecipientIdValid: null,
        isProceed: false,
        isConfirmed: null,
        isProcessing: false,
        isCommitted: null,
        isSuccessful: null,
    },
};

const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setChannel: (state: TransactionState, action: PayloadAction<Channel | null>) => {
            state.channel = action.payload;
            if (action.payload === null) {
                state.amount = 0;
                state.percentage = 0;
                state.amountToBuy = 0;
                state.selectedCurrency = null;
                if (state.channel === Channel.Swap || state.channel === Channel.TokenBuy || state.channel === Channel.TokenSell) {
                    state.selectedCurrencyToBuy = null;
                } else if (state.channel === Channel.Withdrawal || state.channel === Channel.TopUp) {
                    state.selectedNetwork = null;
                    state.address = null;
                } else if (state.channel === Channel.TokensFrozen) {
                    state.selectedStakingTerm = null;
                } else if (state.channel === Channel.Transfer) {
                    state.selectedRecipient = null;
                }
            }
        },
        setSelectedCurrency: (state: TransactionState, action: PayloadAction<Currency | null>) => {
            state.selectedCurrency = action.payload;
        },
        setSelectedCurrencyToBuy: (state: TransactionState, action: PayloadAction<Currency | null>) => {
            state.selectedCurrencyToBuy = action.payload;
        },
        setSelectedNetwork: (state: TransactionState, action: PayloadAction<Network | null>) => {
            state.selectedNetwork = action.payload;
        },
        setAmount: (state: TransactionState, action: PayloadAction<number>) => {
            state.amount = action.payload;
            
            if (state.limits.min === 0 || state.limits.max === 0 || action.payload === 0) {
                state.status.isAmountValid = null;
            } else if (state.limits.min > action.payload || state.limits.max < action.payload) {
                state.status.isAmountValid = false;
            } else if (state.limits.min <= action.payload && state.limits.max >= action.payload && action.payload > 0) {
                state.status.isAmountValid = true;
            }
        },
        setPercentage: (state: TransactionState, action: PayloadAction<number>) => {
            state.percentage = action.payload;
        },
        setAmountToBuy: (state: TransactionState, action: PayloadAction<number>) => {
            state.amountToBuy = action.payload;
        },
        setLimits: (state: TransactionState, action: PayloadAction<{ min: number, max: number }>) => {
            state.limits = action.payload;
            if (state.amount > 0) {
                if (action.payload.max < state.amount || action.payload.min > state.amount) {
                    state.status.isAmountValid = false;
                } else if (action.payload.max >= state.amount && action.payload.min >= state.amount) {
                    state.status.isAmountValid = true;
                }
            } else {
                state.status.isAmountValid = null;
            }
        },
        setIsEnoughBalance: (state: TransactionState, action: PayloadAction<boolean | null>) => {
            state.status.isEnoughBalance = action.payload;
        },
        setAddress: (state: TransactionState, action: PayloadAction<string | null>) => {
            state.address = action.payload;
        },
        setSelectedRecipient: (state: TransactionState, action: PayloadAction<ITransferRecipient | null>) => {
            state.selectedRecipient = action.payload;
            if (action.payload) {
                state.status.isRecipientIdValid = true;
            } else {
                state.status.isRecipientIdValid = null;
            }
        },
        setSelectedStakingTerm: (state: TransactionState, action: PayloadAction<ITokenStakingTerm | null>) => {
            state.selectedStakingTerm = action.payload;
        },
        setIsRecipientIdValid: (state: TransactionState, action: PayloadAction<boolean | null>) => {
            state.status.isRecipientIdValid = action.payload;
        },
        setIsProceed: (state: TransactionState, action: PayloadAction<boolean>) => {
            state.status.isProceed = action.payload;
        },
        setIsProcessing: (state: TransactionState, action: PayloadAction<boolean>) => {
            state.status.isProcessing = action.payload;
        },
        setIsConfirmed: (state: TransactionState, action: PayloadAction<boolean>) => {
            state.status.isConfirmed = action.payload;
        },
        setIsCommitted: (state: TransactionState, action: PayloadAction<boolean>) => {
            state.status.isCommitted = action.payload;
        },
        setIsSuccessful: (state: TransactionState, action: PayloadAction<boolean | null>) => {
            state.status.isSuccessful = action.payload;
        },
        resetTransactionState: (state: TransactionState) => {
            state.channel = null;
            state.selectedCurrency = null;
            state.selectedCurrencyToBuy = null;
            state.selectedNetwork = null;
            state.amount = 0;
            state.percentage = 0;
            state.amountToBuy = 0;
            state.limits = { min: 0, max: 0 };
            state.address = null;
            state.selectedRecipient = null;
            state.selectedStakingTerm = null;
            state.selectedCloudMininPlan = null;
            state.status.isEnoughBalance = null;
            state.status.isAmountValid = null;
            state.status.isAddressValid = null;
            state.status.isRecipientIdValid = null;
            state.status.isProceed = false;
            state.status.isConfirmed = null;
            state.status.isProcessing = false;
            state.status.isCommitted = null;
            state.status.isSuccessful = null;
        }
    },
});

export const {
    setChannel,
    setSelectedCurrency,
    setSelectedCurrencyToBuy,
    setSelectedNetwork,
    setAmount,
    setPercentage,
    setAmountToBuy,
    setLimits,
    setIsEnoughBalance,
    setAddress,
    setSelectedRecipient,
    setSelectedStakingTerm,
    setIsRecipientIdValid,
    setIsProceed,
    setIsConfirmed,
    setIsProcessing,
    setIsCommitted,
    setIsSuccessful,
    resetTransactionState,
} = transactionSlice.actions;

export default transactionSlice.reducer;
