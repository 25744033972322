'use client';

import { DialogContent, DialogFooter, DialogDescription, DialogTitle, DialogHeader } from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { useUserProfile } from "@/hooks/useUserProfile";
import { Locale } from "@/types/enums";
import { RocketIcon } from "lucide-react";
import { ReactNode, useCallback, useEffect, useLayoutEffect, useState } from "react";

const locales = Locale;

const SetupMenu: React.FC<{ userId: number }> = ({ userId }) => {
    const { getSeedPhrase } = useUserProfile(userId);
    const [step, setStep] = useState<number>(0);
    const [title, setTitle] = useState<string | null>(null);
    const [desc, setDesc] = useState<string | null>(null);
    const [body, setBody] = useState<ReactNode | null>(null);
    const [seedPhrase, setSeedPhrase] = useState<string[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const fetchSeedPhrase = useCallback(async () => {
        setIsFetching(true);
        try {
            const seedPhrase = await getSeedPhrase();
            setSeedPhrase(seedPhrase.seed_phrase);
        } catch (error: any) {
            setIsError(true);
        } finally {
            setIsFetching(false);
        }
    }, []);

    const handleStepChange = useCallback((step: number) => {
        switch (step) {
            case 1:
                setTitle('Welcome!');
                setDesc('to TelexCrypto');
                setBody(
                    <div>
                        <RocketIcon className="size-120" />
                    </div>
                );
                fetchSeedPhrase();
                break;
            case 2:
                setTitle('Get seed phrase');
                setDesc('Check it');
                setBody(
                    <div>
                        {isFetching || !seedPhrase ? (
                            <Skeleton className="w-full h-10" />
                        ) : (
                            <ul>
                                {seedPhrase.map(word => (
                                    <li key={word}><code>{word}</code></li>
                                ))}
                            </ul>
                        )}
                    </div>
                )
                break;
            case 3:
                setTitle('Choose Language');
                setDesc('Select language');
                setBody(
                    <div>
                        {JSON.stringify(locales)}
                    </div>
                )
                break;
        }
    }, [seedPhrase, isFetching]);

    useEffect(() => {
        if (step) handleStepChange(step);
    }, [step, handleStepChange]);

    useLayoutEffect(() => {
        setStep(1);
    }, []);

    return (
        <DialogContent className="h-[85vh]">
            <DialogHeader>
                <DialogTitle>{title}</DialogTitle>
                <DialogDescription>{desc}</DialogDescription>
            </DialogHeader>
            <div>
                {body}
            </div>
            <DialogFooter>
                <div onClick={() => setStep(step + 1)}>Next</div>
            </DialogFooter>
        </DialogContent>
    );
};

export default SetupMenu;