'use client';

import { logError } from '@/lib/actions';
import { IToastAction } from '@/types/api';
import { ColorScheme, Level } from '@/types/enums';
import { IBottomButtonParams } from '@/types/telegram';
import { createContext, useContext } from 'react';

interface StyleContextType {
    colorScheme: ColorScheme;
    styles: {
        textColor: string,
        buttonTextColor: string,
        destructiveTextColor: string,
        accentTextColor: string,
        sectionHeaderTextColor: string,
        buttonColor: string,
        bgColor: string,
        sectionBgColor: string,
        secondaryBgColor: string,
        bottomBarBgColor: string,
        subtitleTextColor: string,
        headerBgColor: string,
        sectionSeparatorBgColor: string,
        hintColor: string,
        linkColor: string,
        successColor: string,
        dangerColor: string,
        warningColor: string,
        infoColor: string,
        blackColor: string,
        whiteColor: string,
        goldColor: string,
    },
    colors: {
        success: string,
        danger: string,
        warning: string,
        info: string,
        light: string,
        dark: string,
        sectionBg: {
            light: string,
            dark: string,
        },
        sectionSeparator: {
            light: string,
            dark: string,
        },
        secondaryBgColor: {
            light: string,
            dark: string,
        }
    };
    toastThemes: Record<string, any>;
    getTrendColor: (trendFactor: string | number) => string;
    getErrorToast: (toastId: string) => IToastAction;
    getLoadingToast: (toastId: string) => IToastAction;
    getBalanceColor: (balance: number) => string;
    getLevelColor: (level?: Level) => string;
    getStatusColor: (status: string) => string;
};

export const StyleContext = createContext<StyleContextType | undefined>(undefined);

export const useStyle = () => {
    const context = useContext(StyleContext);
    if (!context) {
        logError('useStyle must be used within a StyleProvider');
        throw new Error('useStyle must be used within a StyleProvider');
    }
    return context;
};
