"use client";

import { NotificationOccurredFunction } from '@/hooks/useHapticFeedback';
import { DisableMainButtonFunction, EnableMainButtonFunction, HideMainButtonFunction, HideMainButtonProgressFunction, RestoreMainButtonParamsFunction, SetMainButtonParamsFunction, ShowMainButtonFunction, ShowMainButtonProgressFunction, StoreMainButtonParamsFunction } from '@/hooks/useMainButton';
import { DisableSecondaryButtonFunction, EnableSecondaryButtonFunction, HideSecondaryButtonFunction, HideSecondaryButtonProgressFunction, SetSecondaryButtonParamsFunction, ShowSecondaryButtonFunction, ShowSecondaryButtonProgressFunction } from '@/hooks/useSecondaryButton';
import { logError } from '@/lib/actions';
import { HapticFeedbackImpactLevel, HapticNotificationType, IBottomButton, IBottomButtonParams, IChatType, ICloudStorage, IDownloadFileParams, IEmojiStatusParams, IHomeScreenStatus, IPopupParams, IScanQrPopupParams, IStoryShareParams, IWebApp } from '@/types/telegram';
import { createContext, useContext } from 'react';

interface TelegramContextType {
    webApp: IWebApp;
    cloudStorage: {
        getItem: (key: string) => Promise<string | null>;
        setItem: (key: string, value: string) => Promise<void>;
        getItems: (keys: string[]) => Promise<Record<string, string | null>>;
        removeItem: (key: string) => Promise<void>;
        removeItems: (keys: string[]) => Promise<void>;
        getKeys: () => Promise<string[]>;
    };
    hapticFeedback: {
        notification: NotificationOccurredFunction;
        impact: (level: HapticFeedbackImpactLevel) => void;
        selectionChanged: () => void;
    },
    mainButton: {
        show: ShowMainButtonFunction;
        hide: HideMainButtonFunction;
        setParams: SetMainButtonParamsFunction;
        enable: EnableMainButtonFunction;
        disable: DisableMainButtonFunction;
        showProgress: ShowMainButtonProgressFunction;
        hideProgress: HideMainButtonProgressFunction;
        storeParams: StoreMainButtonParamsFunction;
        restoreParams: RestoreMainButtonParamsFunction;
    };
    secondaryButton: {
        show: ShowSecondaryButtonFunction;
        hide: HideSecondaryButtonFunction;
        setParams: SetSecondaryButtonParamsFunction;
        enable: EnableSecondaryButtonFunction;
        disable: DisableSecondaryButtonFunction;
        showProgress: ShowSecondaryButtonProgressFunction;
        hideProgress: HideSecondaryButtonProgressFunction;
    };
    backButton: {
        show: () => void;
        hide: () => void;
    };
    settingsButton: {
        show: () => void;
        hide: () => void;
    };
    enableClosingConfirmation: () => void;
    disableClosingConfirmation: () => void;
    enableVerticalSwipes: () => void;
    disableVerticalSwipes: () => void;
    sendData: (data: string) => void;
    showAlert: (message: string, callback?: () => void) => void;
    showPopup: (params: IPopupParams, callback?: (button_id: string) => void) => void;
    showConfirm: (message: string, callback?: (result: boolean) => void) => void;
    showScanQrPopup: (params?: IScanQrPopupParams, callback?: (text: string) => boolean) => void;
    closeScanQrPopup: () => void;
    setHeaderColor: (color: string) => void;
    setBackgroundColor: (color: string) => void;
    setFooterColor: (color: string) => void;
    requestFullscreen: () => void;
    exitFullscreen: () => void;
    lockOrientation: () => void;
    unlockOrientation: () => void;
    addToHomeScreen: () => void;
    checkHomeScreenStatus: (callback?: (status: IHomeScreenStatus) => void) => void;
    switchInlineQuery: (query: string, chatType?: IChatType[]) => void;
    openLink: (url: string, options?: { try_instant_view?: boolean }) => void;
    openTelegramLink: (url: string) => void;
    openInvoice: (url: string, callback?: (status: string) => void) => void;
    shareMessage: (msg_id: string, callback?: () => void) => void;
    shareToStory: (media_url: string, params?: IStoryShareParams) => void;
    setEmojiStatus: (custom_emoji_id: string, params: IEmojiStatusParams, callback?: (success: boolean) => void) => void;
    requestEmojiStatusAccess: (callback?: (granted: boolean) => void) => void;
    downloadFile: (params: IDownloadFileParams, callback?: (accepted: boolean) => void) => void;
};

export const TelegramContext = createContext<TelegramContextType | undefined>(undefined);

export const useTelegram = () => {
    const context = useContext(TelegramContext);
    if (!context) {
        logError('useTelegram must be used within a TelegramProvider');
        throw new Error('useTelegram must be used within a TelegramProvider');
    }
    return context;
};
