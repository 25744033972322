import { createSlice, createAsyncThunk, PayloadAction, isRejectedWithValue } from '@reduxjs/toolkit';
import { ICryptoCurrency, IApiException, IBotUserTransaction, IBotUserWalletStats, IBotUserTokenStaking, IBotUserTransactionsRequest } from '@/types/api';
import { ApiEndpoints } from '@/lib/appConfig';
import { sendGetRequest } from '@/lib/actions';
import ApiException from '@/exceptions/ApiException';
import { RootState } from './store';
import { addColorTransparency } from '@/lib/utils';

export const fetchCryptoCurrencies = createAsyncThunk<ICryptoCurrency[], number, { rejectValue: IApiException }>(
    'wallet/fetchCryptoCurrencies',
    async (userId, { rejectWithValue }) => {
        try {
            const apiResponse = await sendGetRequest<ICryptoCurrency[] | IApiException>(ApiEndpoints.wallet.GET_CRYPTO_CURRENCIES, null, userId);

            if (!apiResponse.success) {
                throw new ApiException(apiResponse.data as IApiException);
            }

            return apiResponse.data as ICryptoCurrency[];
        } catch (error: unknown) {
            if (error instanceof ApiException) {
                const apiError: IApiException = {
                    message: error.message,
                    action: error.apiAction,
                };
                return rejectWithValue(apiError);
            }

            const unexpectedError: IApiException = {
                message: (error as Error).message || 'Fetching cryptocurrencies failed.',
                action: null,
            };
            return rejectWithValue(unexpectedError);
        }
    }
);

export const fetchBotUserTokenStakings = createAsyncThunk<IBotUserTokenStaking[], number, { rejectValue: IApiException }>(
    'wallet/fetchTokenStakes',
    async (userId, { rejectWithValue }) => {
        try {
            const apiResponse = await sendGetRequest<IBotUserTokenStaking[] | IApiException>(ApiEndpoints.wallet.GET_TOKEN_STAKINGS, null, userId);

            if (!apiResponse.success) {
                throw new ApiException(apiResponse.data as IApiException);
            }

            return apiResponse.data as IBotUserTokenStaking[];
        } catch (error: unknown) {
            if (error instanceof ApiException) {
                const apiError: IApiException = {
                    message: error.message,
                    action: error.apiAction,
                };
                return rejectWithValue(apiError);
            }

            const unexpectedError: IApiException = {
                message: (error as Error).message || 'Fetching token stakes failed.',
                action: null,
            };
            return rejectWithValue(unexpectedError);
        }
    }
);

export const fetchBotUserWalletStats = createAsyncThunk<IBotUserWalletStats, number, { rejectValue: IApiException }>(
    'wallet/fetchWalletStats',
    async (userId, { rejectWithValue }) => {
        try {
            const apiResponse = await sendGetRequest<IBotUserWalletStats | IApiException>(ApiEndpoints.wallet.GET_WALLET_STATS, null, userId);

            if (!apiResponse.success) {
                throw new ApiException(apiResponse.data as IApiException);
            }

            return apiResponse.data as IBotUserWalletStats;
            
        } catch (error: unknown) {
            if (error instanceof ApiException) {
                const apiError: IApiException = {
                    message: error.message,
                    action: error.apiAction,
                };
                return rejectWithValue(apiError);
            }

            const unexpectedError: IApiException = {
                message: (error as Error).message || 'Fetching token stakes failed.',
                action: null,
            };
            return rejectWithValue(unexpectedError);
        }
    }
);

interface WalletState {
    cryptoCurrencies: {
        cryptoCurrencies: ICryptoCurrency[],
        isLoading: boolean;
        isSuccessful: boolean | null,
    },
    transactions: {
        transactions: IBotUserTransaction[],
        isLoading: boolean;
        isSuccessful: boolean | null,
    },
    tokenStaking: {
        tokenStakes: IBotUserTokenStaking[];
        isLoading: boolean,
        isSuccessful: boolean | null,
    },
    walletStats: {
        stats: IBotUserWalletStats;
        isLoading: boolean;
        isError: boolean | null;
    };
}

const initialState: WalletState = {
    cryptoCurrencies: {
        cryptoCurrencies: [],
        isLoading: false,
        isSuccessful: null,
    },
    tokenStaking: {
        tokenStakes: [],
        isLoading: false,
        isSuccessful: null,
    },
    transactions: {
        transactions: [],
        isLoading: false,
        isSuccessful: null,
    },
    walletStats: {
        stats: {
            total_balance: '0.0000',
            profit_perc_24h: 0,
            total_profit: '0.00',
            total_invested: '0.0000',
            balance_txir: '0.00',
        },
        isLoading: false,
        isError: null,
    },
};

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setCryptoCurrencies: (state: WalletState, action: PayloadAction<ICryptoCurrency[]>) => {
            state.cryptoCurrencies.cryptoCurrencies = action.payload;
            state.cryptoCurrencies.isLoading = false;
            state.cryptoCurrencies.isSuccessful = true;
        },
        setWalletStats: (state: WalletState, action: PayloadAction<IBotUserWalletStats>) => {
            state.walletStats.stats = action.payload;
            state.walletStats.isError = false;
            state.walletStats.isLoading = false;
        },
        setTransactions: (state: WalletState, action: PayloadAction<IBotUserTransaction[]>) => {
            state.transactions.transactions = action.payload;
            state.transactions.isLoading = false;
            state.transactions.isSuccessful = true;
        },
        setTokenStakes: (state: WalletState, action: PayloadAction<IBotUserTokenStaking[]>) => {
            state.tokenStaking.tokenStakes = action.payload;
            state.tokenStaking.isLoading = false;
            state.tokenStaking.isSuccessful = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCryptoCurrencies.pending, (state) => {
                state.cryptoCurrencies.isLoading = true;
                state.cryptoCurrencies.isSuccessful = null;
                state.cryptoCurrencies.cryptoCurrencies = [];
            })
            .addCase(fetchCryptoCurrencies.fulfilled, (state, action) => {
                state.cryptoCurrencies.isLoading = false;
                state.cryptoCurrencies.cryptoCurrencies = action.payload;
                state.cryptoCurrencies.isSuccessful = true;
            })
            .addCase(fetchCryptoCurrencies.rejected, (state, action) => {
                state.cryptoCurrencies.isLoading = false;
                state.cryptoCurrencies.cryptoCurrencies = [];
                state.cryptoCurrencies.isSuccessful = false;
            })
            .addCase(fetchBotUserTokenStakings.pending, (state) => {
                state.tokenStaking.isLoading = true;
                state.tokenStaking.isSuccessful = null;
                state.tokenStaking.tokenStakes = [];
            })
            .addCase(fetchBotUserTokenStakings.fulfilled, (state, action) => {
                state.tokenStaking.isLoading = false;
                state.tokenStaking.isSuccessful = true;
                state.tokenStaking.tokenStakes = action.payload;
            })
            .addCase(fetchBotUserTokenStakings.rejected, (state, action) => {
                state.tokenStaking.isLoading = false;
                state.tokenStaking.isSuccessful = false;
                state.tokenStaking.tokenStakes = [];
            })
            .addCase(fetchBotUserWalletStats.pending, (state) => {
                state.walletStats.isLoading = true;
                state.walletStats.isError = null;
            })
            .addCase(fetchBotUserWalletStats.fulfilled, (state, action) => {
                state.walletStats.stats = action.payload;
                state.walletStats.isLoading = false;
                state.walletStats.isError = false;
            })
            .addCase(fetchBotUserWalletStats.rejected, (state, action) => {
                state.walletStats.isLoading = false;
                state.walletStats.isError = true;
            });
    },
});

export const {
    setCryptoCurrencies,
    setWalletStats,
    setTransactions,
    setTokenStakes,
} = walletSlice.actions;

export default walletSlice.reducer;