import { createSelector, TaskAbortError } from "@reduxjs/toolkit";
import { RootState } from "@/store/store";
import { ITransactionPayload } from "@/types/api";

export const selectTransactionSlice = (state: RootState) => state.transaction;
const selectCryptoCurrencies = (state: RootState) => state.wallet.cryptoCurrencies;
export const selectSelectedCurrency = (state: RootState) => state.transaction.selectedCurrency;
export const selectSelectedCurrencyToBuy = (state: RootState) => state.transaction.selectedCurrencyToBuy;
export const selectTransactionStatus = (state: RootState) => state.transaction.status;
const selectAmountToSell = (state: RootState) => state.transaction.amount;
const selectAmountToBuy = (state: RootState) => state.transaction.amountToBuy;
export const selectCryptoSlice = (state: RootState) => state.crypto;

export const selectCryptoCurrency = createSelector(
    [
        (state: RootState, isCurrencyToBuy: boolean) =>
            isCurrencyToBuy ? selectSelectedCurrencyToBuy(state) : selectSelectedCurrency(state),
        selectCryptoCurrencies,
    ],
    (selectedCurrency, cryptoCurrencies) => {
        if (!selectedCurrency) return null;
        return cryptoCurrencies.cryptoCurrencies.find((crypto) => crypto.currency === selectedCurrency) || null;
    }
);

export const selectAmount = (isAmountToBuy?: boolean | null) =>
    createSelector(
        [isAmountToBuy ? selectAmountToBuy : selectAmountToSell],
        (amount) => amount
    );

export const selectTransactionPayload = createSelector(
    [selectTransactionSlice, selectCryptoSlice],
    (transactionSlice, cryptoSlice) => {
        if (!transactionSlice.channel || !transactionSlice.selectedCurrency || !cryptoSlice.cryptoFees) {
            return null;
        }

        const payload: ITransactionPayload = {
            channel: transactionSlice.channel,
            currency: transactionSlice.selectedCurrency,
            currency_bought: transactionSlice.selectedCurrencyToBuy,
            amount: transactionSlice.amount,
            percentage: transactionSlice.percentage,
            network: transactionSlice.selectedNetwork,
            address: transactionSlice.address,
            referral_id: transactionSlice.selectedRecipient?.user_id ?? null,
            duration: transactionSlice.selectedStakingTerm?.duration ?? null,
            plan_id: transactionSlice.selectedCloudMininPlan?.plan_id ?? null,
            fee: cryptoSlice.cryptoFees.fee,
            threshold: cryptoSlice.cryptoFees.threshold
        };

        return payload;
    }
);