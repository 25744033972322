'use client';

import { IBottomButton, IBottomButtonParams, IChatType, IDownloadFileParams, IEmojiStatusParams, IPopupParams, IScanQrPopupParams, IStoryShareParams, IWebApp } from "@/types/telegram";
import { memo, ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { TelegramContext } from "@/contexts/telegram-context";
import { AppDispatch } from "@/store/store";
import { useDispatch } from "react-redux";
import { setActive } from "@/store/userSlice";
import { logDebug, logInfo, logWarning } from "@/lib/actions";
import useSettingsButton from "@/hooks/useSettingsButton";
import useSecondaryButton from "@/hooks/useSecondaryButton";
import useCloudStorage from "@/hooks/useCloudStorage";
import useMainButton from "@/hooks/useMainButton";
import useBackButton from "@/hooks/useBackButton";
import { AppConfig } from "@/lib/appConfig";
import useHapticFeedback from "@/hooks/useHapticFeedback";

const minVersionFor = AppConfig.telegram.minRequiredVersionFor;

export const TelegramProvider = ({ children, webApp }: { children: ReactNode; webApp: IWebApp }) => {
    const dispatch: AppDispatch = useDispatch();
    const memoWebApp = useMemo(() => webApp, [webApp]);
    const cloudStorage = useCloudStorage(memoWebApp.CloudStorage);
    const hapticFeedback = useHapticFeedback(memoWebApp.HapticFeedback);
    const mainButton = useMainButton(memoWebApp.MainButton);
    const backButton = useBackButton(memoWebApp.BackButton);
    const settingsButton = useSettingsButton(memoWebApp.SettingsButton);
    const secondaryButton = useSecondaryButton(memoWebApp.SecondaryButton);

    const setHeaderColor = useCallback((color: string) => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.SET_HEADER_COLOR)) {
            if (memoWebApp.headerColor !== color) {
                memoWebApp.setHeaderColor(color);
            }
        }
    }, [memoWebApp]);

    const setBackgroundColor = useCallback((color: string) => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.SET_BACKGROUND_COLOR)) {
            if (memoWebApp.backgroundColor !== color) {
                logInfo('Setting bg color.');
                memoWebApp.setBackgroundColor(color);
            }
        } else {
            logWarning(`TelegramProvider: version doens't support changing BG color.`);
        }
    }, [memoWebApp]);

    const setFooterColor = useCallback((color: string) => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.SET_FOOTER_COLOR)) {
            if (memoWebApp.bottomBarColor !== color) {
                memoWebApp.setBottomBarColor(color);
            }
        }
    }, [memoWebApp]);

    const requestFullscreen = useCallback(() => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.FULLSCREEN)) {
            if (!memoWebApp.isFullscreen) {
                memoWebApp.requestFullscreen();
            }
        }
    }, [memoWebApp]);

    const exitFullscreen = useCallback(() => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.FULLSCREEN)) {
            if (memoWebApp.isFullscreen) {
                memoWebApp.exitFullScreen();
            }
        }
    }, [memoWebApp]);

    const handleActivated = useCallback(() => {
        dispatch(setActive(true));
    }, []);

    const handleDeactivated = useCallback(() => {
        dispatch(setActive(false));
    }, []);

    const handleClipboardTextReceived = useCallback((event: { text: string | null; }) => {

    }, []);

    const enableClosingConfirmation = useCallback(() => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.CLOSING_CONFIRMATION)) {
            if (!memoWebApp.isClosingConfirmationEnabled) {
                memoWebApp.enableClosingConfirmation();
            }
        }
    }, [memoWebApp]);

    const disableClosingConfirmation = useCallback(() => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.CLOSING_CONFIRMATION)) {
            if (webApp.isClosingConfirmationEnabled) {
                memoWebApp.disableClosingConfirmation();
            }
        }
    }, [memoWebApp]);

    const enableVerticalSwipes = useCallback(() => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.VERTICAL_SWIPES)) {
            if (!memoWebApp.isVerticalSwipesEnabled) {
                webApp.enableVerticalSwipes();
            }
        }
    }, [memoWebApp]);

    const disableVerticalSwipes = useCallback(() => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.VERTICAL_SWIPES)) {
            if (memoWebApp.isVerticalSwipesEnabled) {
                memoWebApp.disableVerticalSwipes();
            }
        }
    }, [memoWebApp]);

    const sendData = useCallback((data: string) => {
        memoWebApp.sendData(data);
    }, [memoWebApp]);

    const lockOrientation = useCallback(() => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.ORIENTATION)) {
            memoWebApp.lockOrientation();
        }
    }, [memoWebApp]);

    const unlockOrientation = useCallback(() => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.ORIENTATION)) {
            memoWebApp.unlockOrientation();
        }
    }, [memoWebApp]);

    const addToHomeScreen = useCallback(() => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.ADDING_TO_HOME_SCREEN)) {
            memoWebApp.addToHomeScreen();
        }
    }, [memoWebApp]);

    const checkHomeScreenStatus = useCallback(() => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.ADDING_TO_HOME_SCREEN)) {
            memoWebApp.checkHomeScreenStatus();
        }
    }, [memoWebApp]);

    const switchInlineQuery = useCallback((query: string, chatType?: IChatType[]) => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.SWITCHING_INLINE_QUERY)) {
            memoWebApp.switchInlineQuery(query, chatType);
        }
    }, [memoWebApp]);

    const showAlert = useCallback((message: string, callback?: () => void) => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.SHOWING_MODALS)) {
            memoWebApp.showAlert(message, callback);
        }
    }, [memoWebApp]);

    const showPopup = useCallback((params: IPopupParams, callback?: (button_id: string) => void) => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.SHOWING_MODALS)) {
            memoWebApp.showPopup(params, callback);
        }
    }, [memoWebApp]);

    const showConfirm = useCallback((message: string, callback?: (result: boolean) => void) => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.SHOWING_MODALS)) {
            memoWebApp.showConfirm(message, callback);
        }
    }, [memoWebApp]);

    const showScanQrPopup = useCallback((params?: IScanQrPopupParams, callback?: (text: string) => boolean) => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.QR)) {
            memoWebApp.showScanQrPopup(params, callback);
        }
    }, [memoWebApp]);

    const closeScanQrPopup = useCallback(() => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.QR)) {
            memoWebApp.closeScanQrPopup();
        }
    }, [memoWebApp]);

    const openTelegramLink = useCallback((url: string): void => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.TELEGRAM_WEB_BROWSER)) {
            memoWebApp.openTelegramLink(url);
        }
    }, [memoWebApp]);

    const openLink = useCallback((url: string, options?: { try_instant_view?: boolean }): void => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.OPENING_LINKS)) {
            memoWebApp.openLink(url, options);
        }
    }, [memoWebApp]);

    const openInvoice = useCallback((url: string, callback?: (status: string) => void): void => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.INVOICES)) {
            memoWebApp.openInvoice(url, callback);
        }
    }, [memoWebApp]);

    const shareToStory = useCallback((media_url: string, params?: IStoryShareParams): void => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.STORIES)) {
            memoWebApp.shareToStory(media_url, params);
        }
    }, [memoWebApp]);

    const shareMessage = useCallback((msg_id: string, callback?: (success: boolean) => void): void => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.SHARING_MESSAGES)) {
            memoWebApp.shareMessage(msg_id, callback);
        }
    }, [memoWebApp]);

    const setEmojiStatus = useCallback((custom_emoji_id: string, params?: IEmojiStatusParams, callback?: (success: boolean) => void) => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.EMOJI_STATUS)) {
            memoWebApp.setEmojiStatus(custom_emoji_id, params, callback);
        }
    }, [memoWebApp]);

    const requestEmojiStatusAccess = useCallback((callback?: (granted: boolean) => void) => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.EMOJI_STATUS)) {
            memoWebApp.requestEmojitatusAccess(callback);
        }
    }, [memoWebApp])

    const downloadFile = useCallback((params: IDownloadFileParams, callback?: (accepted: boolean) => void): void => {
        if (memoWebApp.isVersionAtLeast(minVersionFor.DOWNLOAD_FILE)) {
            memoWebApp.downloadFile(params, callback);
        }
    }, [memoWebApp]);

    useLayoutEffect(() => {
        logDebug('Theme params 0', memoWebApp.themeParams);
        mainButton.hide();
        secondaryButton.hide();
        setBackgroundColor(memoWebApp.themeParams.secondary_bg_color ?? '#10b981');
        setHeaderColor(memoWebApp.themeParams.header_bg_color ?? '#10b981');
        setFooterColor(memoWebApp.themeParams.bottom_bar_bg_color ?? '#10b981');
        backButton.hide();
        memoWebApp.expand();
        disableClosingConfirmation();
        disableVerticalSwipes();
        lockOrientation();
        logDebug('Theme params 1', memoWebApp);
    }, [memoWebApp, mainButton, secondaryButton, backButton, disableVerticalSwipes, disableClosingConfirmation, setBackgroundColor]);

    return (
        <TelegramContext.Provider value={{
            webApp,
            cloudStorage,
            hapticFeedback,
            mainButton,
            secondaryButton,
            backButton,
            settingsButton,
            enableClosingConfirmation,
            disableClosingConfirmation,
            enableVerticalSwipes,
            disableVerticalSwipes,
            sendData,
            showAlert,
            showPopup,
            showConfirm,
            showScanQrPopup,
            closeScanQrPopup,
            openLink,
            openTelegramLink,
            openInvoice,
            setHeaderColor,
            setBackgroundColor,
            setFooterColor,
            requestFullscreen,
            exitFullscreen,
            addToHomeScreen,
            checkHomeScreenStatus,
            switchInlineQuery,
            lockOrientation,
            unlockOrientation,
            shareToStory,
            shareMessage,
            setEmojiStatus,
            requestEmojiStatusAccess,
            downloadFile,
        }}>
            {children}
        </TelegramContext.Provider>
    )
};
