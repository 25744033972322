'use client';

import { useUi } from '@/contexts/ui-context';
import { Button } from './ui/button';
import { useTranslation } from 'react-i18next';
import { Level } from '@/types/enums';
import { CopyIcon } from 'lucide-react';

type CopyFieldProps = {
    label: string;
    rawValue: any;
    formattedValue: any;
    isDisabled?: boolean;
};

const CopyField: React.FC<CopyFieldProps> = ({ label, formattedValue, rawValue, isDisabled = false }) => {
    const { t } = useTranslation();
    const { showToast } = useUi();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(rawValue);
        showToast({
            toastId: 'copied',
            title: 'Ok!',
            description: t('copied'),
            type: Level.Success,
            duration: 1200,
            closeButton: false,
        });
    }

    return (
        <div className="w-full bg-section rounded-sm pr-2 p-4 h-12 flex items-center justify-between">
            <div className="flex flex-col" style={{ maxWidth: 'calc(100% - 80px)' }}>
                <span className="text-muted text-[8px] leading-relaxed">{label}</span>
                <span className="text-purple text-sm font-semibold">
                    <code>{formattedValue}</code>
                </span>
            </div>
            <Button variant="ghost" size="icon" disabled={isDisabled} onClick={copyToClipboard}>
                <CopyIcon />
            </Button>
        </div>
    )
}

export default CopyField;
