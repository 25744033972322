'use client';

import { useApp } from "../contexts/app-context";
import Loader from "@/components/Loader";
import { Loader2Icon, LoaderCircleIcon } from "lucide-react";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

export default function HomePage() {
    const router = useRouter();
    const { user, isAuthorized } = useApp();
    const timeoutDuration = 5000;

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (user && isAuthorized) {
            router.push(user.redirect_url);
        } else {
            timeoutId = setTimeout(() => {
                if (!isAuthorized) {
                    router.push('/static/?route=unauthorized'); 
                }
            }, timeoutDuration);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isAuthorized, user]);

    return (
        null
    );
}
