'use client';

import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useTelegram } from "@/contexts/telegram-context";
import { toast, useSonner } from 'sonner';
import { UiContext } from "@/contexts/ui-context";
import { IToastAction } from '@/types/api';
import { IBottomButton, IBottomButtonParams, IPopupParams } from '@/types/telegram';
import { ColorScheme, IMainButtonType, ISecondaryButtonType } from '@/types/enums';
import { useRouter } from 'next/navigation';
import MainMenu from "@/components/MainMenu";
import { Drawer } from "@/components/ui/drawer";
import { Dialog } from "@/components/ui/dialog";
import { Sheet } from "@/components/ui/sheet";
import { AlertDialog } from "@/components/ui/alert-dialog";
import SetupMenu from "@/components/SetupMenu";
import { t } from "i18next";
import { logDebug } from "@/lib/actions";
import { useStyle } from "@/contexts/style-context";
import { useTranslation } from "react-i18next";
import { StyledEngineProvider } from "@mui/material";
import { darkenHexColor } from "@/lib/utils";
import { SettingsMenu } from "@/components/SettingsMenu";
import { setIsEnoughBalance } from "@/store/transactionSlice";

export const UiProvider = ({ children }: { children: ReactNode }) => {
    const { webApp } = useTelegram();
    const router = useRouter();
    const { toasts } = useSonner();
    const { styles } = useStyle();
    const { i18n } = useTranslation();
    const { mainButton, secondaryButton, backButton, setHeaderColor, setFooterColor, setBackgroundColor } = useTelegram();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [drawerContent, setDrawerContent] = useState<ReactNode | null>(null);
    const [isSheetOpen, setIsSheetOpen] = useState<boolean>(false);
    const [sheetContent, setSheetContent] = useState<ReactNode | null>(null);
    const [isMainMenuOpen, setIsMainMenuOpen] = useState<boolean>(false);
    const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState<boolean>(false);
    const [mainMenu, setMainMenuContent] = useState<ReactNode | null>(null);
    const [settingsMenu, setSettingsMenuContent] = useState<ReactNode | null>(null);

    const showModal = useCallback((content: ReactNode) => {
        if (!isModalOpen) {
            setIsModalOpen(true);
            setModalContent(content);
        }
    }, []);

    const hideModal = useCallback(() => {
        setIsModalOpen(false);
        setModalContent(null);
    }, []);

    const showDialog = useCallback((content: ReactNode) => {
        setDialogContent(content);
        setIsDialogOpen(true);
    }, []);

    const hideDialog = useCallback(() => {
        setIsDialogOpen(false);
        setDialogContent(null);
    }, []);

    const showDrawer = useCallback((content: ReactNode) => {
        setDrawerContent(content);
        setIsDrawerOpen(true);
    }, []);

    const hideDrawer = useCallback(() => {
        setIsDrawerOpen(false);
        setDrawerContent(null);
    }, []);

    const showSheet = useCallback((content: ReactNode) => {
        setSheetContent(content);
        setIsSheetOpen(true);
    }, []);

    const hideSheet = useCallback(() => {
        setIsSheetOpen(false);
        setSheetContent(null);
    }, []);

    const showToast = useCallback((toastData: IToastAction) => {
        switch (toastData.type) {
            case 'error':
                toast.info(toastData.title, {
                    id: toastData.toastId,
                    description: toastData.description,
                    duration: toastData.duration,
                    closeButton: toastData.closeButton,
                    dismissible: toastData.dismissible,
                });
                break;
            case 'warning':
                toast.warning(toastData.title, {
                    id: toastData.toastId,
                    description: toastData.description,
                    duration: toastData.duration,
                    closeButton: toastData.closeButton,
                    dismissible: toastData.dismissible,
                });
                break;
            case 'info':
                toast.info(toastData.title, {
                    id: toastData.toastId,
                    description: toastData.description,
                    duration: toastData.duration,
                    closeButton: toastData.closeButton,
                    dismissible: toastData.dismissible,
                });
                break;
            case 'success':
                toast.success(toastData.title, {
                    id: toastData.toastId,
                    description: toastData.description,
                    duration: toastData.duration,
                    closeButton: toastData.closeButton,
                    dismissible: toastData.dismissible,
                });
                break;
            default:
                toast.message(toastData.title, {
                    id: toastData.toastId,
                    description: toastData.description,
                    duration: toastData.duration,
                    closeButton: toastData.closeButton,
                    dismissible: toastData.dismissible,
                    onDismiss: toastData?.onDismiss,
                    onAutoClose: toastData?.onAutoclose,
                });
                break;
        }
    }, []);

    const isToastVisible = useCallback((id: string | number) => {
        return toasts.some((t: { id: string | number; }) => t.id === id);
    }, [toasts]);

    const hideToast = useCallback((toastId?: string) => {
        if ((toastId && isToastVisible(toastId)) || !toastId) {
            toast.dismiss(toastId);
        }
    }, [isToastVisible]);

    const showLoadingToast = (toastId: string) => {
        toast.loading(t('loading'), {
            id: toastId,
            description: t('please_wait'),
        });
    };

    const wasToastActive = useCallback((id: number | string) => {
        return toast.getHistory().some((t) => t.id === id);
    }, []);

    const showToastOnce = useCallback((content: IToastAction) => {
        if (!wasToastActive(content.toastId)) {
            showToast(content);
        }
    }, [showToast, wasToastActive]);

    const showSingleToast = useCallback((content: IToastAction) => {
        if (!isToastVisible(content.toastId)) {
            showToast(content);
        }
    }, [showToast]);

    const closeMainMenu = useCallback((): void => {
        setIsMainMenuOpen(false);
    }, []);

    const openMainMenu = useCallback((): void => {
        setIsMainMenuOpen(true);
        setMainMenuContent(
            <MainMenu
                onSelect={(menu: string) => {
                    router.push(`/${webApp.initDataUnsafe.user?.id}/${menu}/`);
                    setIsMainMenuOpen(false);
                }}
            />
        );
    }, [webApp.initDataUnsafe.user?.id]);

    const openSettingsMenu = useCallback((): void => {
        setIsSettingsMenuOpen(true);
        setSettingsMenuContent(<SettingsMenu userId={webApp.initDataUnsafe.user?.id as number} />);
    }, []);

    const closeSettingsMenu = useCallback((): void => {
        setIsSettingsMenuOpen(false);
    }, []);

    const showSetupMenu = useCallback((): void => {
        showDialog(<SetupMenu userId={webApp.initDataUnsafe.user?.id as number} />)
    }, [showDialog, webApp.initDataUnsafe.user]);

    const hideSetupMenu = useCallback((): void => {
        hideDialog();
    }, [hideDialog]);

    const setMainButtonParams = useCallback((buttonType: IMainButtonType): IBottomButton => {
        let params: IBottomButtonParams | null = null;
        switch (buttonType) {
            case 'menu':
                params = {
                    text: t('menu'),
                    text_color: styles.textColor,
                    color: styles.sectionSeparatorBgColor,
                    is_active: true,
                    is_visible: true,
                    has_shine_effect: false,
                };
                break;
            case 'close':
                params = {
                    text: t('close'),
                    text_color: styles.textColor,
                    color: styles.sectionSeparatorBgColor,
                    is_active: true,
                    is_visible: true,
                    has_shine_effect: true,
                };
                break;
            case 'continue':
                params = {
                    text: t('continue'),
                    text_color: styles.textColor,
                    color: styles.sectionSeparatorBgColor,
                    is_active: true,
                    is_visible: true,
                    has_shine_effect: true,
                };
                break;
            case 'confirm':
                params = {
                    text: t('confirm'),
                    text_color: styles.textColor,
                    color: styles.sectionSeparatorBgColor,
                    is_active: true,
                    is_visible: true,
                    has_shine_effect: true,
                };
                break;
            case 'not_enough_balance':
                params = {
                    text: t('insufficient_balance'),
                    text_color: styles.textColor,
                    color: styles.sectionSeparatorBgColor,
                    is_active: true,
                    is_visible: true,
                    has_shine_effect: false,
                };
                break;
            case 'loading':
                params = {
                    text_color: styles.textColor,
                    color: styles.sectionSeparatorBgColor,
                    is_active: false,
                    is_visible: true,
                    has_shine_effect: false,
                };
                return mainButton.setParams(params).showProgress(false);
            case 'tokens_frozen':
                params = {
                    text: 'Stake!',
                    text_color: styles.textColor,
                    color: styles.buttonColor,
                    is_active: true,
                    is_visible: true,
                    has_shine_effect: true,
                }
                break;
            case 'home':
                params = {
                    text: t('home'),
                    text_color: styles.textColor,
                    color: styles.sectionSeparatorBgColor,
                    is_active: true,
                    is_visible: true,
                    has_shine_effect: false,
                };
                break;
            case 'token_purchase':
                params = {
                    text: t('buy'),
                    text_color: styles.sectionHeaderTextColor,
                    color: styles.sectionSeparatorBgColor,
                    is_active: true,
                    is_visible: true,
                    has_shine_effect: false,
                };
                break;
            case 'token_sale':
                params = {
                    text: t('sell'),
                    text_color: styles.sectionHeaderTextColor,
                    color: styles.sectionSeparatorBgColor,
                    is_active: true,
                    is_visible: true,
                    has_shine_effect: false,
                };
                break;
            case 'referral_payout': 
                params = {
                    text: t('payout'),
                    text_color: styles.sectionHeaderTextColor,
                    color: styles.sectionSeparatorBgColor,
                    is_active: true,
                    is_visible: true,
                    has_shine_effect: false,
                };
                break;
            case 'tokens_unfrozen':
                params = {
                    text: 'Unstake',
                    text_color: styles.buttonTextColor,
                    color: styles.destructiveTextColor,
                    is_active: true,
                    is_visible: true,
                    has_shine_effect: false,
                };
                break;
            default:
                params = {
                    text: t(buttonType),
                    text_color: styles.textColor,
                    color: styles.sectionSeparatorBgColor,
                    is_active: true,
                    is_visible: true,
                    has_shine_effect: false,
                };
                break;
        }

        return mainButton.setParams(params);
    }, [mainButton, i18n.language, styles]);

    const setSecondaryButtonParams = useCallback((secondaryButtonType: ISecondaryButtonType): IBottomButton => {
        let params: IBottomButtonParams | null = null;

        switch (secondaryButtonType) {
            case 'cancel':
                params = {
                    text: t('cancel'),
                    text_color: styles.buttonTextColor,
                    color: styles.destructiveTextColor,
                    is_active: true,
                    is_visible: true,
                };
                break;
            case 'home':
                params = {
                    text: t('home'),
                    text_color: styles.buttonTextColor,
                    color: styles.sectionSeparatorBgColor,
                    is_active: true,
                    is_visible: false,
                };
                break;
        }

        return secondaryButton.setParams(params);
    }, [secondaryButton, i18n.language, styles]);

    useEffect(() => {
        if (isModalOpen || isDialogOpen || isDrawerOpen || isSheetOpen || isSettingsMenuOpen) {
            setHeaderColor(darkenHexColor(styles.headerBgColor, 0.80));
            setFooterColor(darkenHexColor(styles.bottomBarBgColor, 0.80));
        } else if (isMainMenuOpen) {
            setHeaderColor(darkenHexColor(styles.headerBgColor, 0.80));
        } else {
            setHeaderColor(styles.headerBgColor);
            setFooterColor(styles.bottomBarBgColor);
        }
    }, [isModalOpen, isDialogOpen, isDrawerOpen, isSheetOpen, isMainMenuOpen, isSettingsMenuOpen, styles, setHeaderColor, setFooterColor]);

    // useEffect(() => {
    //     if (!webApp.MainButton.isActive) {
    //         mainButton.setParams({ text_color: styles.sectionHeaderTextColor });
    //     } else {
    //         mainButton.setParams({ text_color: styles.sectionSeparatorBgColor });
    //     }
    // }, [webApp.MainButton.isActive, mainButton, styles.sectionHeaderTextColor, styles.sectionSeparatorBgColor]);

    useEffect(() => {
        if (isDrawerOpen || isSheetOpen) {
            mainButton.hide();
            secondaryButton.hide();
            backButton.hide();
        } else {
            mainButton.show();
            secondaryButton.show();
            backButton.show();
        }
    }, [isDrawerOpen, isSheetOpen, mainButton, secondaryButton, backButton]);

    return (
        <UiContext.Provider value={{
            isModalOpen,
            showModal,
            hideModal,
            isDialogOpen,
            showDialog,
            hideDialog,
            isDrawerOpen,
            showDrawer,
            hideDrawer,
            isSheetOpen,
            isMainMenuOpen,
            showSheet,
            hideSheet,
            showToast,
            hideToast,
            showSingleToast,
            showToastOnce,
            wasToastActive,
            isToastVisible,
            showLoadingToast,
            openMainMenu,
            closeMainMenu,
            openSettingsMenu,
            closeSettingsMenu,
            showSetupMenu,
            hideSetupMenu,
            setMainButtonParams,
            setSecondaryButtonParams,

        }}>
            {settingsMenu && (
                <Dialog open={isSettingsMenuOpen} onOpenChange={closeSettingsMenu}>
                    {settingsMenu}
                </Dialog>
            )}
            {children}
            {mainMenu && (
                <Drawer open={isMainMenuOpen} onOpenChange={closeMainMenu} onDrag={closeMainMenu}>
                    {mainMenu}
                </Drawer>
            )}
            {modalContent && (
                <AlertDialog open={isModalOpen} onOpenChange={hideModal} defaultOpen={false}>
                    {modalContent}
                </AlertDialog>
            )}
            {drawerContent && (
                <Drawer open={isDrawerOpen} onOpenChange={hideDrawer} onDrag={hideDrawer}>
                    {drawerContent}
                </Drawer>
            )}
            {dialogContent && (
                <Dialog open={isDialogOpen} onOpenChange={hideDialog}>
                    {dialogContent}
                </Dialog>
            )}
            {sheetContent && (
                <Sheet open={isSheetOpen} onOpenChange={hideSheet}>
                    {sheetContent}
                </Sheet>
            )}
        </UiContext.Provider>
    )
};
