'use client';

import { LocaleContext } from "@/contexts/locale-context";
import { logAlert, logDebug, logError } from "@/lib/actions";
import i18n from "@/lib/i18n";
import { isLocaleSupported } from "@/lib/utils";
import { setDefaultAutoSelectFamilyAttemptTimeout } from "net";
import { ReactNode, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";

export const LocaleProvider = ({ children, languageCode }: { children: ReactNode, languageCode?: string }) => {
    const { ready } = useTranslation();
    const [locale, setLocale] = useState<string>('en');
    const [isReadu, setIsReady] = useState(false); 

    const switchLanguage = async (locale: string): Promise<boolean> => {
        try {
            if (locale === i18n.language) return true;
            await i18n.changeLanguage(locale);
            logDebug('Language switched!');
            setLocale(locale);
            return true;
        } catch (error: any) {
            logError('LocaleProvider: failed to change language.', error);
            return false;
        }
    };

    const handleLanguageChanged = useCallback((data: any) => {
        logDebug('LocaleProvider: handleLanguageChanged', data);
    }, []);

    const handleInitialized = useCallback((data: any) => {
        logDebug('LocaleProvider: handleInitialized', data);
    }, []);

    const handleFailedLoading = useCallback((lng: string) => {
        logError(`LocaleProvider: language ${lng} failed loading.`);
        if (lng !== 'en') i18n.changeLanguage('en');
    }, []);

    const handleLoaded = useCallback((data: any) => {
        logDebug('LocaleProvider: handleLoaded', data);
    }, []);

    const handleMissingKey = useCallback((data: any) => {
        logDebug('LocaleProvider: handleMissingKey', data);
        logAlert('LocaleProvider: translation key is missing.', data)
    }, []);

    useEffect(() => {
        if (ready) {
            logDebug('Locale provider is ready.');
        } else {
            logDebug('Locale provider is not ready.');
        }
    }, [ready]);

    useEffect(() => {
        if (languageCode) {
            if (isLocaleSupported(languageCode)) {
                void switchLanguage(languageCode);
            } else {
                logAlert(`LocaleProvider: unsupported language code: ${languageCode}`);
            }
        }
    }, [languageCode]);

    useEffect(() => {
        i18n.on('languageChanged', handleLanguageChanged);
        i18n.on('initialized', handleInitialized);
        i18n.on('loaded', handleLoaded);
        i18n.on('failedLoading', handleFailedLoading);
        i18n.on('missingKey', handleMissingKey);

        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
            i18n.off('initialized', handleInitialized);
            i18n.off('loaded', handleLoaded);
            i18n.off('failedLoading', handleFailedLoading);
            i18n.off('missingKey', handleMissingKey);
        }
    }, [handleLanguageChanged, handleInitialized, handleLoaded, handleFailedLoading, handleMissingKey]);

    return (
        <I18nextProvider i18n={i18n}>
            <LocaleContext.Provider value={{ locale, switchLanguage }}>
                {children}
            </LocaleContext.Provider>
        </I18nextProvider>
    )
};