'use client';

import { logError } from '@/lib/actions';
import { IToastAction } from '@/types/api';
import { IMainButtonType, ISecondaryButtonType } from '@/types/enums';
import { IBottomButton } from '@/types/telegram';
import { createContext, ReactNode, useContext } from 'react';

interface UiContextType {
    isModalOpen: boolean;
    showModal: (content: ReactNode) => void;
    hideModal: () => void;
    isDialogOpen: boolean;
    showDialog: (content: ReactNode) => void;
    hideDialog: () => void;
    isDrawerOpen: boolean;
    showDrawer: (content: ReactNode) => void;
    hideDrawer: () => void;
    isSheetOpen: boolean;
    showSheet: (content: ReactNode) => void;
    hideSheet: () => void;
    showToast: (toast: IToastAction) => void;
    hideToast: (toastId?: string) => void;
    showSingleToast: (content: IToastAction) => void;
    showToastOnce: (content: IToastAction) => void;
    isToastVisible: (toastId: string) => boolean;
    wasToastActive: (toastId: string) => boolean;
    showLoadingToast: (toastId: string) => void;
    isMainMenuOpen: boolean;
    openMainMenu: () => void;
    closeMainMenu: () => void;
    openSettingsMenu: () => void;
    closeSettingsMenu: (menu?: string, param?: string) => void;
    showSetupMenu: () => void;
    hideSetupMenu: () => void;
    setMainButtonParams: (buttonType: IMainButtonType) => IBottomButton;
    setSecondaryButtonParams: (buttonType: ISecondaryButtonType) => IBottomButton;
};

export const UiContext = createContext<UiContextType | undefined>(undefined);

export const useUi = () => {
    const context = useContext(UiContext);
    if (!context) {
        logError('useUi must be used within a UiProvider');
        throw new Error('useUi must be used within a UiProvider');
    }
    return context;
};
