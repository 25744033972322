
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";

type UserState = {
    isAuthorized: boolean | null;
    isActive: boolean;
};

const initialState: UserState = {
    isAuthorized: null,
    isActive: true,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthorized: (state: UserState, action: PayloadAction<boolean>) => {
            state.isAuthorized = action.payload;
        },
        setActive: (state: UserState, action: PayloadAction<boolean>) => {
            state.isActive = action.payload;
        },
    },
});

export const {
    setAuthorized,
    setActive,
} = userSlice.actions;

export default userSlice.reducer;
