'use client';

import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RocketIcon } from "lucide-react";
import Image from 'next/image';
import Link from "next/link";
import { useApp } from "@/contexts/app-context";
import { RootState } from "@/store/store";
import { useSelector } from "react-redux";

export default function Header() {
    const { t } = useTranslation();
    const { step, user } = useApp();
    const pathname = usePathname();
    const [title, setTitle] = useState<string | null>(null);
    const isAuthorized = useSelector((state: RootState) => state.user.isAuthorized);
    // const imageUrl = webApp.initDataUnsafe.user?.photo_url ?? '/assets/logo-dex.png';

    useEffect(() => {
        if (pathname.endsWith('/top-up')) {
            if (step === 1) {
                setTitle(t('select_currency.title'));
            } else if (step === 2) {
                setTitle(t('select_network.title'));
            } else {
                setTitle(t('top_up'));
            }
        } else if (pathname.endsWith('/withdraw')) {
            setTitle(t('withdrawal'));
        } else if (pathname.endsWith('/transfer')) {
            setTitle(t('transfer'));
        } else if (pathname.endsWith('/swap')) {
            setTitle(t('swap'));
        } else {
            setTitle(null);
        }
    }, [t, pathname, step]);

    return null;

    return (
        <header className="flex flex-row items-center justify-between p-4">
            {/* <Link href='/'>
                <Image
                    src={webApp.initDataUnsafe.user?.photo_url ?? '/assets/logo-dex.png'}
                    alt="TelexCrypto"
                    height={120}
                    width={122}
                    className={`w-6 h-6 object-contain ${webApp.initDataUnsafe.user?.photo_url ? 'rounded-full' : ''}`}
                />
            </Link> */}
            <div className="flex flex-row space-x-2">
                <RocketIcon className="h-6 h-6 text-muted-foreground" />
                <h1 className="text-xl font-bold">{title}</h1>
            </div>
        </header>
    )
};