'use client';

import { ICloudStorage } from '@/types/telegram';
import { useCallback, useMemo } from 'react';

export type GetItemFunction = (key: string) => Promise<string | null>;
export type SetItemFunction = (key: string, value: string) => Promise<void>;
export type GetItemsFunction = (keys: string[]) => Promise<Record<string, string | null>>;
export type RemoveItemFunction = (key: string) => Promise<void>;
export type RemoveItemsFunction = (key: string[]) => Promise<void>;
export type GetKeysFunction = () => Promise<string[]>;

const useCloudStorage = (cloudStorage: ICloudStorage): {
    getItem: GetItemFunction;
    setItem: SetItemFunction;
    getItems: GetItemsFunction;
    removeItem: RemoveItemFunction;
    removeItems: RemoveItemsFunction;
    getKeys: GetKeysFunction;
} => {
    const getItem: GetItemFunction = useCallback(key =>
        new Promise((resolve, reject) => {
            cloudStorage.getItem(key, (error, value) => {
                if (!error) {
                    resolve(value);
                } else {
                    reject(error);
                }
            });
        }),
    [cloudStorage]);

    const setItem: SetItemFunction = useCallback((key, value) =>
        new Promise((resolve, reject) => {
            cloudStorage?.setItem(key, value, (error, state) => {
                if (!error && state) {
                    resolve();
                } else {
                    reject(error);
                }
            });
        }),
    [cloudStorage]);

    const getItems: GetItemsFunction = useCallback(key =>
        new Promise((resolve, reject) => {
            cloudStorage.getItems(key, (error, value) => {
                if (!error && value) {
                    resolve(value);
                } else {
                    reject(error);
                }
            });
        }),
    [cloudStorage]);

    const removeItem: RemoveItemFunction = useCallback(key =>
        new Promise((resolve, reject) => {
            cloudStorage.removeItem(key, (error, state) => {
                if (!error && state) {
                    resolve();
                } else {
                    reject(error);
                }
            });
        }),
    [cloudStorage]);

    const removeItems: RemoveItemsFunction = useCallback(key =>
        new Promise((resolve, reject) => {
            cloudStorage.removeItems(key, (error, state) => {
                if (!error && state) {
                    resolve();
                } else {
                    reject(error);
                }
            });
        }),
    [cloudStorage]);

    const getKeys: GetKeysFunction = useCallback(() =>
        new Promise((resolve, reject) => {
            cloudStorage.getKeys((error, state) => {
                if (!error && state) {
                    resolve(state);
                } else {
                    reject(error);
                }
            });
        }),
    [cloudStorage]);

    return useMemo(() => ({ getItem, setItem, getItems, removeItem, removeItems, getKeys }), [cloudStorage]);
};

export default useCloudStorage;
